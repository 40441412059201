import React, { useState } from 'react'
import './Header.scss'
import { Link } from 'react-router-dom'
import { ICategoriesListItem } from '../../Interfaces/interfaces'
import { transformStringIntoUrlPath } from '../../utils/utils'
import { useAppSelector } from '../../redux/store'
import { selectUser } from '../../redux/user.slice'
import SearchBar from '../SearchBar/SearchBar'
import CategoryItem from './CategoryItem/CategoryItem'
import { Container, Navbar, Offcanvas, Image, Nav, Button } from 'react-bootstrap'
import logoWhite from '../../Assets/images/full-logo-white.png'
import logoBlack from '../../Assets/images/full-logo-black.png'
import ProfileNav from './ProfileNav/ProfileNav'
import { selectCategoriesList } from '../../redux/category.slice'

export type CategoryWithPath = ICategoriesListItem & { path: string; children: CategoryWithPath[] }

function Header() {
  const categories = useAppSelector(selectCategoriesList)

  const [isOffcanvasShow, setIsOffcanvasShow] = useState<boolean>(false)
  const handleOffcanvasShow = () => setIsOffcanvasShow(true)
  const handleOffcanvasHide = () => setIsOffcanvasShow(false)

  const user = useAppSelector(selectUser)

  // add field 'path' which describes the url for category, the function give paths to all offsprings
  function getCategoriesPaths(categories: ICategoriesListItem[]): any[] {
    return categories.map((category) => {
      let path = transformStringIntoUrlPath(category.name)
      if (category.children.length !== 0) {
        return { ...category, path, children: getCategoriesPaths(category.children) }
      }
      return { ...category, path }
    })
  }

  const categoriesWithPaths: CategoryWithPath[] = getCategoriesPaths(categories)
  return (
    <Navbar expand="xl" fixed="top">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Image height={33} src={logoWhite} alt="header-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleOffcanvasShow} />
        <Navbar.Offcanvas
          scroll={true}
          backdrop={true}
          placement="start"
          show={isOffcanvasShow}
          onHide={handleOffcanvasHide}
          className="flex-grow-0"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id="offcanvasNavbarLabel">
              <Link to="/" className="me-auto">
                <Image height={33} src={logoBlack} alt="header-logo" />
              </Link>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="align-items-center pt-0">
            {/*Navigation, mapping all categories and path to them based on their name*/}

            <Nav className="mb-3 mb-xl-0 text-nowrap">
              {categoriesWithPaths.map((category) => (
                <CategoryItem key={category.id} category={category} onClick={handleOffcanvasHide} />
              ))}
            </Nav>
            <div className="d-xl-block d-none">
              <SearchBar onSearch={handleOffcanvasHide} />
            </div>
            {user ? (
              <ProfileNav hideOffcanvas={handleOffcanvasHide} avatar={user?.avatar} />
            ) : (
              <Link to="/getaccess">
                <Button className="btn-orange mb-3 mb-xl-0">Login</Button>
              </Link>
            )}
            <div className="d-xl-none d-block">
              <SearchBar onSearch={handleOffcanvasHide} />
            </div>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  )
}

export default Header
