import React, { Component, ReactNode, useEffect } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import Home from './Pages/Home/Home'
import Header from './Components/Header/Header'
import Footer from './Components/Footer/Footer'
import './App.scss'
import Profile from './Pages/Profile/Profile'
import { fetchUser, selectUser, selectUserIsFetching } from './redux/user.slice'
import TextPage from './Pages/TextPage/TextPage'
import SearchResults from './Pages/SearchResults/SearchResults'
import MyCommentsAndActivity from './Pages/MyCommentsAndActivity/MyCommentsAndActivity'
import PrivacyPolicy from './Pages/InformationPages/PrivacyPolicy/PrivacyPolicy'
import TermsOfService from './Pages/InformationPages/Terms/Terms'
import ContactUs from './Pages/InformationPages/ContactUs/ContactUs'
import Notifications from './Pages/Notifications/Notifications'
import Followers from './Pages/Followers/Followers'
import PublicProfile from './Pages/PublicProfile/PublicProfile'
import Loader from './Components/Loader/Loader'
import {
  CONTACT_US_PATH,
  FOLLOWERS_PATH,
  HOME_PATH,
  MY_ACTIVITY_PATH,
  MY_PROFILE_PATH,
  NOTIFICATIONS_PATH,
  PRIVACY_POLICY_PATH,
  PUBLIC_PROFILE_PATH,
  SEARCH_RESULTS_PATH,
  SOURCE_PAGE_PATH,
  TERMS_OF_SERVICE_PATH, TREAD_PATH,
} from './utils/const'
import { useAppDispatch, useAppSelector } from './redux/store'
import { pusher } from './Api/sockets'
import { INotification } from './Interfaces/interfaces'
import { addNotification } from './redux/notification.slice'
import Tread from './Pages/Tread/Tread'

class ProtectedRoute extends Component<{ children: ReactNode }> {
  render() {
    let { children } = this.props

    const token = localStorage.getItem('token')

    if (!token) {
      return <Navigate to="/getaccess" replace />
    }

    return children
  }
}

function App() {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isFetching = useAppSelector(selectUserIsFetching)
  const user = useAppSelector(selectUser)
  useEffect(() => {
    !user &&
      dispatch(fetchUser())
        .unwrap()
        .catch(() => navigate('/getaccess'))
  }, [])
  useEffect(() => {
    const channel = user && pusher.subscribe(`private-notifications_${user.id}`)
    channel &&
      channel.bind(`App\\Events\\RealTimeNotificationEvent`, (notification: { data: INotification }) => {
        dispatch(addNotification(notification.data))
      })
    return () => {
      user && pusher.unsubscribe(`private-notifications_${user?.id}`)
      channel && channel.unbind(`App\\Events\\RealTimeNotificationEvent`)
    }
  }, [user?.id])
  if (isFetching) return <Loader />
  return (
    <>
      <div className="wrapper d-flex flex-column">
        <Header />
        <main className="flex-grow-1">
          <div className="content-wrapper container">
            <Routes>
              <Route path={MY_PROFILE_PATH} element={<ProtectedRoute children={<Profile />} />} />
              <Route path={MY_ACTIVITY_PATH} element={<ProtectedRoute children={<MyCommentsAndActivity />} />} />
              <Route path={FOLLOWERS_PATH} element={<ProtectedRoute children={<Followers />} />} />
              <Route path={NOTIFICATIONS_PATH} element={<ProtectedRoute children={<Notifications />} />}></Route>
              <Route path={HOME_PATH + ':id?'} element={<Home />} />
              <Route path={SOURCE_PAGE_PATH + '/:id'} element={<TextPage />} />
              <Route path={PUBLIC_PROFILE_PATH + '/:id'} element={<PublicProfile />} />
              <Route path={SEARCH_RESULTS_PATH + '/*'} element={<SearchResults />} />
              <Route path={PRIVACY_POLICY_PATH} element={<PrivacyPolicy />} />
              <Route path={TERMS_OF_SERVICE_PATH} element={<TermsOfService />} />
              <Route path={CONTACT_US_PATH} element={<ContactUs />} />
              <Route path={TREAD_PATH+'/:id'} element={<Tread />} />
            </Routes>
          </div>
        </main>
        <Footer></Footer>
      </div>
    </>
  )
}

export default App
