import { combineReducers, configureStore, createAction, createReducer, Reducer } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import userReducer from './user.slice'
import textReducer from './text.slice'
import commentReducer from './comment.slice'
import notificationReducer from './notification.slice'
import sourcesReducer from './sources.slice'
import categoryReducer from './category.slice'

const combinedReducers = combineReducers({
  user: userReducer,
  text: textReducer,
  comment: commentReducer,
  notification: notificationReducer,
  sources: sourcesReducer,
  category: categoryReducer,
})

const rootReducer:Reducer = (state, action)=>{
  if(action.type==='logout')
    return combinedReducers(undefined,action)
  return combinedReducers(state,action)
}

export const store = configureStore({
  reducer: rootReducer,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducers>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

// @ts-ignore
window.store = store
