import React from 'react'
import profileImg from '../../Assets/icons/account_circle_black.svg'
import iconLike from '../../Assets/icons/Icon-like.svg'
import iconComment from '../../Assets/icons/Icon-reply.svg'
import './SourceItems.scss'
import { ITextResponseAPI } from '../../Interfaces/interfaces'
import { Link } from 'react-router-dom'
import LinesEllipsis from 'react-lines-ellipsis'
import { Card, Col, Image, Row, Stack } from 'react-bootstrap'
import { SOURCE_PAGE_PATH } from '../../utils/const'

function SourceItems({ texts, newSourcesIds }: { newSourcesIds?: number[]; texts: ITextResponseAPI[] }) {
  return (
    <Row>
      {texts.map((text) => (
        <Col xs={12} key={text.id} className={newSourcesIds?.includes(text.id) ? 'blinkAnimation' : undefined}>
          <Card className="card my-1">
            <Card.Body as={Link} style={{ textDecoration: 'none' }} to={SOURCE_PAGE_PATH + '/' + text.id}>
              <Card.Title as={'h1'}>
                <Stack direction="horizontal" gap={2}>
                  <Image
                    style={{ objectFit: 'contain' }}
                    src={text.favicon || profileImg}
                    width={24}
                    height={24}
                    alt=""
                  />
                  {text.title}
                </Stack>
              </Card.Title>
              <Card.Subtitle as={'h6'} className="text-muted mb-2">
                {new Date(text.created_at).toDateString()}
              </Card.Subtitle>
              {/*Content*/}
              <Card.Text as="div" className="mb-4">
                <LinesEllipsis text={text.content || ''} ellipsis="..." maxLine={3} />
              </Card.Text>
              <Stack direction="horizontal" gap={2} className="justify-content-between">
                {/*Like button*/}
                <Stack direction="horizontal" gap={2} className="align-items-end">
                  <Card.Link as="div">
                    <Image className="card-icon" src={iconLike} width={24} height={24} alt="" />
                  </Card.Link>
                  {text.total_likes_count}
                </Stack>
                {/*Comment button*/}
                <Stack direction="horizontal" gap={2}>
                  <Card.Link as="div">
                    <Image className="card-icon" src={iconComment} width={24} height={24} alt="" />
                  </Card.Link>
                  {text.total_comments_count}
                </Stack>
              </Stack>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

export default SourceItems
