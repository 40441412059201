import React from 'react'
import { useNavigate } from 'react-router-dom'
import './NotificationItem.scss'
import { INotification, INotificationType } from '../../Interfaces/interfaces'
import profilePhoto from '../../Assets/icons/account_circle_black.svg'
import notificationIcon from '../../Assets/icons/group-notification-icon.png'
import { useInView } from 'react-intersection-observer'
import { SOURCE_PAGE_PATH } from '../../utils/const'

const notificationMessagesSingle: Record<INotificationType, string> = {
  reply: 'has replied',
  comment: 'comment your post',
  mention: 'mentioned you',
  likeComment: 'liked your comment',
  likeReply: 'liked your reply',
  unreadReplies: 'You have unread replies',
  unreadComments: 'You have unread comment',
  followingLeftComment: 'left a comment',
  followingLeftReply: 'left a comment',
  followingLikedComment: 'liked a comment',
  followingLikedReply: 'liked a comment',
  followingStartFollow: 'start follow',
  followingStoredText: 'left a comment',
  message: '',
}

const notificationMessagesGroup: Record<INotificationType, string> = {
  unreadReplies: 'unread replies',
  unreadComments: 'unread comments',
  mention: 'mention you',
  comment: '',
  reply: '',
  likeComment: 'likes on your comment',
  likeReply: 'likes on your reply',
  followingLeftComment: '',
  followingLeftReply: '',
  followingLikedComment: '',
  followingLikedReply: '',
  followingStartFollow: '',
  followingStoredText: '',
  message: '',
}

const NotificationItem = ({
  notification,
  notificationGroup,
  makeRead,
}: {
  notification: INotification,
  notificationGroup:string
  makeRead: (id: number, group: string) => void
}) => {
  const navigate = useNavigate()

  const openNotification = (notification: INotification, group: boolean, type: INotificationType) => {
    if (notification.id) {
      if (
        type === 'reply' ||
        type === 'mention' ||
        type === 'likeReply' ||
        type === 'unreadReplies' ||
        type === 'followingLeftReply' ||
        type === 'followingLikedReply'
      ) {
        if (notification.comment && notification.reply_id) {
          navigate(`${SOURCE_PAGE_PATH}/${notification.comment.text.id}#${notification.reply_id}`)
        }
      }
      if (type === 'unreadComments' || type === 'followingLeftComment') {
        if (notification.comment) {
          navigate(`${SOURCE_PAGE_PATH}/${notification.comment.text.id}#${notification.comment.id}`)
        }
      }
      if ((type === 'likeComment' || type === 'followingLikedComment') && notification.comment) {
        if (notification.comment) {
          navigate(`${SOURCE_PAGE_PATH}/${notification.comment.text.id}#${notification.comment.id}`)
        }
      }
      if (type === 'followingStoredText' && notification.text) {
        if (notification.text) {
          navigate(`${SOURCE_PAGE_PATH}/${notification.text.id}`)
        }
      }
    }
  }

  const getReplies = (notification: INotification) => {
    const comment = notification.comment
    const replyId = notification.reply_id ? +notification.reply_id : null
    if (replyId && comment?.replies) {
      return {
        reply: comment.replies.find((reply) => reply.id === replyId)?.content,
        reply_id: replyId,
      }
    }
    return null
  }

  const getNotificationMessage = (notification: INotification) => {
    const reply = getReplies(notification)
    const name = notification.user ? notification.user.name : ''

    if (notification.group && notification.count && notification.count > 1) {
      return `You have ${notification.count} ${notificationMessagesGroup[notification.type]}`
    }

    if (reply && notification.type !== 'followingLeftReply' && notification.type !== 'followingLikedReply') {
      return `${name} ${notificationMessagesSingle[notification.type]}  "${reply.reply}"`
    }

    if (
      notification.type === 'followingLeftComment' ||
      notification.type === 'followingLeftReply' ||
      notification.type === 'followingStoredText'
    ) {
      const commentContent =
        notification.type === 'followingLeftComment'
          ? notification.comment?.content
          : notification.type === 'followingLeftReply'
          ? findReplyContent(notification)
          : notification.text?.title
      return `${name} left a comment '${commentContent}'`
    }

    if (notification.type === 'followingLikedComment' || notification.type === 'followingLikedReply') {
      const likedCommentContent =
        notification.type === 'followingLikedComment' ? notification.comment?.content : findReplyContent(notification)
      return `${name} liked a comment '${likedCommentContent}'`
    }

    if (notification.type === 'followingStartFollow') {
      return `${notification.subscription?.follower.name} started following ${notification.subscription?.following.name}`
    }

    if (notification.type !== 'unreadReplies' && notification.type !== 'unreadComments') {
      return `${name} ${notificationMessagesSingle[notification.type]}`
    }

    return `${notificationMessagesSingle[notification.type]} from user "${name}"`
  }

  function findReplyContent(notification: INotification) {
    return notification.comment?.replies.find((reply) => +reply.id === +notification.reply_id!)?.content
  }

  const getPhoto = (iconSrc: string | null | undefined, group: boolean, type: INotificationType) => {
    if (iconSrc) {
      return <img alt="notification-img" className="notification-item-image" src={iconSrc} />
    } else {
      if (group || type === 'message') {
        return (
          <div className={'notification-group-container'}>
            <div
              className="notification-group-image"
              style={{
                background: 'center / 80% no-repeat url(' + notificationIcon + ')',
              }}
            />
          </div>
        )
      } else {
        return (
          <div className="no-photo">
            <img src={profilePhoto} alt="no-photo" />
          </div>
        )
      }
    }
  }
  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: (inView, entry) => {
      if (inView && entry && !notification.read) makeRead(notification.id,notificationGroup)
    },
  })

  return (
    <div
      className="notification-container d-flex align-items-center"
      onClick={() => {
        openNotification(notification, notification.group, notification.type)
      }}
    >
      {!notification.read && <div ref={ref} />}
      {getPhoto(
        notification.user
          ? notification.user.avatar
          : notification.subscription
          ? notification.subscription.follower.avatar
          : null,
        notification.group,
        notification.type
      )}

      <div className="notification-text d-flex flex-column">
        <span className="text-truncate">{getNotificationMessage(notification)}</span>
      </div>
      <div className={`notification-item-new-notification ${!notification.read && 'new'}`} />
    </div>
  )
}

export default NotificationItem
