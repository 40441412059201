import instance from '../Api/axiosConfig'
import { AxiosResponse } from 'axios'
import { IAuthResponse } from '../Interfaces/responceInterfaces'

export default class AuthService {
  static async login(email: string, password: string): Promise<AxiosResponse<IAuthResponse>> {
    return instance.post('/login', { email, password })
  }

  static async registration(
    email: string,
    name: string,
    username: string,
    password: string,
    password_confirmation: string
  ): Promise<AxiosResponse<IAuthResponse>> {
    return instance.post('/register', { email, name, username, password, password_confirmation })
  }

  static async logout(): Promise<void> {
    return instance.post('/logout')
  }

  static async forgotPassword(email: string): Promise<void> {
    return instance.post('/forgot', { email })
  }

  static async isTokenAlive(email: string,token:string): Promise<{
    success: boolean
    data: any[]
    message: string
  }> {
    return instance.post('/can-reset', { email,token })
  }

  static async resetPassword(
    email: string,
    token: string,
    password: string,
    password_confirmation: string
  ): Promise<void> {
    return instance.post('/reset', { email, token, password, password_confirmation })
  }
}
