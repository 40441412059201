import { FC, useRef, useState } from "react";
import AvatarEditor from "react-avatar-editor";

import "../ProfileComponents.scss";
import { updateUser } from '../../../Api/api'
import { setUser } from '../../../redux/user.slice'
import { useAppDispatch } from '../../../redux/store'

export const CropProfilePhoto: FC<{
  profilePhoto: string | File;
  newImage: (url: string, blob: Blob) => void;
}> = ({ profilePhoto, newImage }) => {
  const editor = useRef<AvatarEditor>(null);
  const [zoom, setZoom] = useState("1");
  const dispatch = useAppDispatch()
  return (
    <div className="crop-profile-photo-container">
      <AvatarEditor
        ref={editor}
        image={profilePhoto}
        width={250}
        height={250}
        borderRadius={1000}
        color={[255, 255, 255, 0.6]} // RGBA
        scale={+zoom}
        rotate={0}
      />
      <div className="config-container">
        <input
          name="scale"
          type="range"
          min="1"
          max="2"
          step="0.01"
          value={zoom}
          onChange={(ev) => {
            setZoom(ev.target.value);
          }}
        />
        <div>
          <button
            className='btn btn-orange rounded-5'
            onClick={(e)=>{
              e.preventDefault()
              newImage('',new Blob())
            }}
          >
            Cancel
          </button>
          <button
            className='btn btn-orange rounded-5'
            onClick={(e) => {
              e.preventDefault()
              if (editor.current) {
                const canvas = editor.current.getImageScaledToCanvas().toDataURL();
                //@ts-ignore
                window.disableDraging = false;
                fetch(canvas)
                  .then((res) => res.blob())
                  .then((blob) => {
                    const formData = new FormData()
                    formData.append('avatar',new File([blob],'avatar'))
                    formData.append('web-context','')
                    updateUser(formData)
                      .then(res=>dispatch(setUser(res.data.user)))
                    newImage(URL.createObjectURL(blob), blob)
                  })
              }
            }}
          >
            Select</button>
        </div>
      </div>
    </div>
  );
};
