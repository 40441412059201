import React, { Component, ReactNode } from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import 'bootstrap/dist/js/bootstrap.min'
import Login from './Pages/Login/Login'
import PreLogin from './Pages/PreLogin/PreLogin'
import { Provider } from 'react-redux'
import { store } from './redux/store'
import Registration from './Pages/Registration/Registration'
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword'
import ScrollToTop from './Components/ScrollToTop/ScrollToTop'
import ResetPassword from './Pages/ResetPassword/ResetPassword'
import Welcome from './Pages/Welcome/Welcome'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

class ProtectedRoute extends Component<{ children: ReactNode }> {
  render() {
    let { children } = this.props

    const token = localStorage.getItem('token')

    if (!token) {
      return <Navigate to="/welcome" replace />
    }

    return children
  }
}

root.render(
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          path="/*"
          index
          element={
            <ProtectedRoute
              children={
                <Provider store={store}>
                  <App />
                </Provider>
              }
            />
          }
        />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/getaccess" element={<Login />} />
        {/*<Route path="/registration" element={<Registration />} />*/}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Routes>
    </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
