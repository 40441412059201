import './Welcome.scss'
import LandingHeader from '../../Components/WelcomeComponents/LandingHeader/LandingHeader'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import React, {useContext, useRef, useState} from 'react'
import {InView} from 'react-intersection-observer'
import {Accordion, AccordionContext, Card, useAccordionButton} from "react-bootstrap";

import headerLogoWhite from '../../Assets/icons/logo.svg'
import chromeIcon from '../../Assets/icons/chrome-icon.svg'
import figure1 from '../../Assets/icons/figure-1.svg'
import figure2 from '../../Assets/icons/figure-2.svg'
import screen1 from '../../Assets/icons/screen-1.png'
import screen2 from '../../Assets/icons/screen-2.png'
import screen3 from '../../Assets/icons/screen-3.png'
import screen4 from '../../Assets/icons/screen-4.png'
import screen5 from '../../Assets/icons/screen-5.png'
import boxArrowLeft from '../../Assets/icons/box-arrow-left.svg'
import boxArrowRight from '../../Assets/icons/box-arrow-right.svg'

const Welcome = () => {
  const [carouselActiveIndex, setCarouselActiveIndex] = useState<number>(0)
  let sliderRef = useRef(null)

  function CustomToggle({children, eventKey, callback}: any) {
    const {activeEventKey} = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
      <div className={`card-header-wrapper ${isCurrentEventKey && "expanded"}`}>
        <span className="header-title"><span className="header-no header-title">0{eventKey}</span> {children}</span>
        <button onClick={decoratedOnClick} className="btn btn-expand">
          <span></span>
          <span></span>
        </button>
      </div>
    );
  }

  return (
    <div className="welcome-wrapper animate-fade-in">
      <InView initialInView>
        {({inView, ref}) => (
          <>
            <div ref={ref}/>
            <LandingHeader className={inView ? 'header-transparent' : ''}/>
          </>
        )}
      </InView>

      <section className="section-welcome d-flex align-items-center">
        <div className="d-flex flex-column w-50">
          <span className="welcome-title">Explore Freely & Engage Boldly. Every Site, a Discovery Forum.</span>
          <span className="text-description welcome-subtitle">Discover with Comment8—your dynamic playground for the web. Stay engaged with our tailored newsfeed that guides you to exciting discoveries aligned with your passions.</span>
          <button className="btn browser-btn">
            <img src={chromeIcon} alt=""/>
            <span>Add To Chrome Browser</span>
          </button>
        </div>
        <div className="welcome-demo-wrapper w-50">
          <img className="img-1" src={figure1} alt=""/>
          <img className="img-2" src={screen1} alt=""/>
          <img className="img-3" src={screen2} alt=""/>
        </div>
      </section>

      <section className="section-2">
        <span className="subtitle">A <span className="subtitle text-gradient">New Frontier</span> in Global Web Interaction.</span>
        <span className="text-description">Comment8 reimagines web exploration, seamlessly blending vibrant community engagement with every page you visit, crafting a uniquely interactive and personalized browsing odyssey.</span>
        <img src={screen3} alt=""/>
      </section>

      <section className="section-3">
        <div className="box-item">
          <span className="box-title"><span className="box-title text-gradient">Boundless</span> Expression</span>
          <span className="box-description">Comment8 removes barriers to digital communication, enabling your insights and ideas to be shared from niche blogs to major social platforms like LinkedIn, Twitter, and Reddit. Your thoughts can travel globally, making a significant impact.</span>
          <img className="img-1" src={boxArrowLeft} alt=""/>
        </div>
        <div className="box-item">
          <span className="box-title"><span className="box-title text-gradient">Elevate</span> Your Voice</span>
          <span className="box-description">Transform every paragraph you read into a platform for expression with Comment8. Highlight text, add comments, and spark conversations directly within your browser, turning your reading experience into an interactive adventure.</span>
          <img className="img-2" src={boxArrowRight} alt=""/>
        </div>
        <div className="box-item">
          <span className="box-title"><span className="box-title text-gradient">Connect</span> and Resonate</span>
          <span className="box-description">Engage in meaningful dialogues. Respond, debate, and navigate through the vast landscape of community interactions. Each click fosters significant connections, enriching your online interactions.</span>
          <img className="img-3" src={boxArrowLeft} alt=""/>
        </div>
        <div className="box-item">
          <span className="box-title"><span
            className="box-title text-gradient">Curate</span> Your Digital Universe</span>
          <span className="box-description">Tailor the web to match your interests with Comment8. Follow intriguing profiles, personalize your interactions, and dive into a curated newsfeed that brings the world’s narratives directly to you.</span>
          <img className="img-4" src={boxArrowRight} alt=""/>
        </div>
        <div className="box-item">
          <span className="box-title">Whole-Page <span className="box-title text-gradient">Wonder</span></span>
          <span className="box-description">Dive deeper with Comment8, where commentary spans across entire web pages. This tool is about more than just marginal notes—it’s about immersing yourself fully in the context of a site for a uniquely comprehensive and engaging experience.</span>
        </div>
      </section>

      <section className="section-4">
        <span className="subtitle"><span className="subtitle text-gradient">Coming Soon:</span><br/>Comment8 - Elevate Your Mobile Engagement</span>
        <span className="text-description">Comment8 reimagines web exploration, seamlessly blending vibrant community engagement with every page you visit, crafting a uniquely interactive and personalized browsing odyssey.</span>
        <div className="img-wrapper">
          <img className="img-1" src={figure2} alt=""/>
          <img className="img-2" src={screen4} alt=""/>
          <img className="img-3" src={screen5} alt=""/>
        </div>
      </section>

      <section className="section-5">
        <span className="subtitle">Frequently Asked Questions</span>

        <Accordion defaultActiveKey="1" flush>
          <Card>
            <Card.Header>
              <CustomToggle eventKey="1">Lorem ipsum dolor sit amet consectetur.</CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>Lorem ipsum dolor sit amet consectetur. In augue ipsum tellus ultrices. Ac pharetra ultrices
                consectetur consequat tellus massa. Nec aliquam cras sagittis duis sed euismod arcu hac. Ornare amet
                ligula ornare lacus aliquam aenean. Eu lacus imperdiet urna amet congue adipiscing. Dictum elit
                velit.</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <CustomToggle eventKey="2">Lorem ipsum dolor sit amet consectetur. Viverra.</CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
              <Card.Body>Lorem ipsum dolor sit amet consectetur. In augue ipsum tellus ultrices. Ac pharetra ultrices
                consectetur consequat tellus massa. Nec aliquam cras sagittis duis sed euismod arcu hac. Ornare amet
                ligula ornare lacus aliquam aenean. Eu lacus imperdiet urna amet congue adipiscing. Dictum elit
                velit.</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <CustomToggle eventKey="3">Lorem ipsum dolor sit amet consectetur. Viverra.</CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
              <Card.Body>Lorem ipsum dolor sit amet consectetur. In augue ipsum tellus ultrices. Ac pharetra ultrices
                consectetur consequat tellus massa. Nec aliquam cras sagittis duis sed euismod arcu hac. Ornare amet
                ligula ornare lacus aliquam aenean. Eu lacus imperdiet urna amet congue adipiscing. Dictum elit
                velit.</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Card.Header>
              <CustomToggle eventKey="4">Lorem ipsum dolor sit amet consectetur. Viverra.</CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
              <Card.Body>Lorem ipsum dolor sit amet consectetur. In augue ipsum tellus ultrices. Ac pharetra ultrices
                consectetur consequat tellus massa. Nec aliquam cras sagittis duis sed euismod arcu hac. Ornare amet
                ligula ornare lacus aliquam aenean. Eu lacus imperdiet urna amet congue adipiscing. Dictum elit
                velit.</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </section>

      <div className="divider"></div>

      <section className="section-6">
        <div className="d-flex align-items-center">
          <img width={50} src={headerLogoWhite} alt=""/>
          <span className="logo-title">Comment8</span>
        </div>
        <div className="subtitle"><span className="subtitle text-gradient">Unlock</span> a New World of Web Interaction!
        </div>
        <div className="text-description">Join our community for a richer, more interactive online experience. Connect,
          share, and discover with every click!
        </div>
        <button className="btn browser-btn">
          <img src={chromeIcon} alt=""/>
          <span>Add To Chrome Browser</span>
        </button>
      </section>

    </div>
  )
}
export default Welcome
