import './LandingFooter.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const LandingFooter = () => {
  return(
      <footer className="welcome-footer">
        <Container>
          <Row className='gap-2 gap-md-0'>
            <Col xs={12} md={4} className="text-start">
              &copy; {new Date(Date.now()).getFullYear()} Comment8 All Rights Reserved
            </Col>
            <Col xs={12} md={4} className="text-md-center">
              <Link to="/terms">Terms Of Use</Link>
            </Col>
            <Col xs={12} md={4} className="text-md-end">
              <Link to="/privacy-policy">Privacy Policy</Link>
            </Col>
          </Row>
        </Container>
      </footer>
  )
}
export default LandingFooter