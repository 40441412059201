import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { ITextCommentAPI } from "../Interfaces/interfaces";
import { getCommentById } from '../Api/api'

// Define a type for the slice state
interface CommentState {
  comment: ITextCommentAPI | null;
  isFetching: boolean;
}

// Define the initial state using that type
const initialState: CommentState = {
  comment: null,
  isFetching: false,
};

export const fetchComment = createAsyncThunk(
  'comment/fetchComment',
  async (id:number)=>{
    const res = await getCommentById(id)
    return res.data.comment
  }
)

export const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {},
  extraReducers:builder => {
    builder
      .addCase(fetchComment.fulfilled,(state, action:PayloadAction<ITextCommentAPI>)=>{
        state.comment=action.payload
        state.isFetching=false
      })
      .addCase(fetchComment.pending,(state)=>{
        state.isFetching=true
      })
  }
});

export const selectComment = (state: RootState) => state.comment.comment;
export const selectIsCommentFetching = (state: RootState) => state.comment.isFetching;

export default commentSlice.reducer;
