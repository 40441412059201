import "./ContactUs.scss";
import React, { FC, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { storeContacts } from "../../../Api/api";
import helpIcon from "../../../Assets/icons/help.svg";
import ModalSuccess from "../../../Components/ModalSuccess/ModalSuccess";

const ContactUsSchema = Yup.object().shape({
  title: Yup.string().required("Please add request title").min(10, "Request title is too short").max(80, "Request title is too long"),
  question: Yup.string()
    .required("Please add request details")
    .min(10, "Request details is too short")
    .max(400, "Request details is too long"),
});

const ContactUs: FC = () => {
  const [isModalShow, setIsModalShow] = useState<boolean>(false);

  return (
    <>
      {isModalShow && (
        <ModalSuccess isModalShow={isModalShow}>The support request has been sent successfully</ModalSuccess>
      )}

      <div className="contact-us-wrapper">
        <div className="contact-us-logo">
          <img src={helpIcon} alt="help" />
          <span className="py-2 fs-3">Need help? Contact us</span>
        </div>
        <Formik
          initialValues={{
            title: "",
            question: "",
          }}
          validationSchema={ContactUsSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            storeContacts({ title: values.title, question: values.question }).then(() => {
              setSubmitting(true);
              setIsModalShow(true);
              setTimeout(() => {
                setIsModalShow(false);
              }, 2000);
              resetForm();
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleSubmit }) => (
            <Form className="contact-us-wrapper-form">
              <label className="contact-label" htmlFor="title">
                Request
                <Field
                  className={`${!!errors.title && touched.title ? "error" : ""} field mb-1`}
                  id="title"
                  type="text"
                  name="title"
                  placeholder="Title"
                  onChange={handleChange}
                />
                <ErrorMessage name="title" component="div" className="field-error" />
              </label>
              <label className="contact-label" htmlFor="desc">
                Request details
                <Field
                  className={`${!!errors.question && touched.question ? "error" : ""} field mb-1`}
                  id="desc"
                  type="textarea"
                  as="textarea"
                  name="question"
                  placeholder="Description"
                  onChange={handleChange}
                />
                <ErrorMessage name="question" component="div" className="field-error" />
              </label>
              <div className="d-flex justify-content-center">
                <button className="btn btn-orange w-75 p-2 mt-4 fs-4 fw-bold rounded-3" type="submit">
                  Send
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default ContactUs;
