import React, { useCallback, useEffect, useState } from 'react'
import SourceItems from '../../Components/SourceItems/SourceItems'
import { useLocation } from 'react-router-dom'
import Loader from '../../Components/Loader/Loader'
import { pusher } from '../../Api/sockets'
import { useInView } from 'react-intersection-observer'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import {
  addSource,
  getAllSources,
  getMoreSources,
  selectSourcesCategoryName,
  selectSourcesIsFetching,
  selectSourcesPagination,
  selectSourcesTexts,
} from '../../redux/sources.slice'
import { ITextResponseAPI } from '../../Interfaces/interfaces'

function Home() {
  const { ref, inView } = useInView()
  const id = useLocation().state?.id
  const dispatch = useAppDispatch()
  const categoryName = useAppSelector(selectSourcesCategoryName)
  const texts = useAppSelector(selectSourcesTexts)
  const pagination = useAppSelector(selectSourcesPagination)
  const isFetching = useAppSelector(selectSourcesIsFetching)
  const [newSourcesIds, setNewSourcesIds] = useState<number[]>([])

  const fetchData = useCallback(() => {
    if (pagination && pagination.currentPage < pagination.lastPage)
      dispatch(
        getMoreSources({
          id,
          page: pagination.currentPage + 1,
        })
      )
  }, [pagination?.currentPage, id])

  useEffect(() => {
    dispatch(getAllSources({ id }))
    const channel = pusher.subscribe(`web_texts`)
    channel.bind(`App\\Events\\RealTimeWebText`, (text: { data: ITextResponseAPI }) => {
      text.data.categories && text.data.categories[0].id === id && dispatch(addSource(text.data))
      setNewSourcesIds((prev) => [...prev, text.data.id])
      setTimeout(() => {
        setNewSourcesIds((prev) => prev.filter((id) => id !== text.data.id))
      }, 2250)
    })
    return () => {
      channel.unbind(`App\\Events\\RealTimeWebText`)
      pusher.unsubscribe(`web_texts`)
    }
  }, [id])

  useEffect(() => {
    inView && fetchData()
  }, [inView])

  if (isFetching) return <Loader />
  return (
    <div>
      <h1 style={{ marginBottom: '-2rem' }} className="fs-1 text-center ">
        {categoryName}
      </h1>
      <div className="mt-5">
        <SourceItems newSourcesIds={newSourcesIds} texts={texts} />
      </div>
      <div ref={ref} />
    </div>
  )
}

export default Home
