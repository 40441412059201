import { useEffect, useState } from "react";
import { getUserSubscriptions } from "../../Api/api";
import { IUser } from "../../Interfaces/interfaces";
import './Followers.scss'
import defaultAvatar from '../../Assets/icons/account_circle_black.svg'
import { Link } from 'react-router-dom'
import { PUBLIC_PROFILE_PATH } from '../../utils/const'

const Followers = () => {
  const [user, setUser] = useState<IUser | null>(null);
  useEffect(() => {
    getUserSubscriptions().then((res) => {
      setUser(res.data.user);
    });
  }, []);

  const renderSubscriptions = (subscriptions: IUser[]) => (
    <div className="items d-grid gap-3">
      {subscriptions.map((follower) => (
        <Link style={{textDecoration:'none',color:'inherit'}} to={PUBLIC_PROFILE_PATH+'/' + follower.id}>
          <div className="fs-5 d-flex gap-3 align-items-center">
            <img width="70" height="70" className="rounded-circle" src={follower.avatar || defaultAvatar} alt="" />
            <p className="mt-2 ">{follower.name}</p>
          </div>
        </Link>
      ))}
    </div>
  )

  if (!user) return <></>;
  return (
    <div className='followers-wrapper row'>
      <div className='col-md-6 col-sm-12'>
        <h1 className="fs-1 mb-2">Followers ({user.followers_count})</h1>
        {renderSubscriptions(user.followers!)}
      </div>
      <div className='col-md-6 col-sm-12'>
        <h1 className="fs-1 mb-2">Followings ({user.followings_count})</h1>
        {renderSubscriptions(user.followings!)}
      </div>
    </div>
  );
};
export default Followers;
