import axiosInstance from "./axiosConfig";
import {
  ICommentAPI,
  ICommentMarkAsReadRequest,
  IContactUsRequest,
  ILoginRequest,
  INotificationMarkAsReadRequest,
  IRegisterRequest,
  ISearchRequest,
  ITextAPI,
  ITextUpdateRequest,
  IUserFollowRequest,
} from "../Interfaces/requestInterfaces";
import {
  IApiResponse,
  IAuthResponse,
  ICategoryResponse,
  ICommentResponse,
  IGlobalDataResponse,
  INewsfeedResponse,
  INotificationResponse,
  ISearchResponse,
  ITextResponse,
  ITextsResponse,
  IUserCommentsResponse,
  IUserResponse,
} from "../Interfaces/responceInterfaces";
import { ICategoriesListItem, ITextCommentAPI, ITextResponseAPI, IUser } from '../Interfaces/interfaces'

export const login = (authData: ILoginRequest): Promise<IApiResponse<IAuthResponse>> =>
  axiosInstance.post("/login", JSON.stringify(authData));

export const registration = (body: IRegisterRequest): Promise<IApiResponse<IAuthResponse>> =>
  axiosInstance.post("/register", body);

/**Comments**/

export const storeComment = (body: ICommentAPI): Promise<IApiResponse<ICommentResponse>> =>
  axiosInstance.post("/comment", body);

export const updateComment = (body: ICommentAPI, id: number): Promise<IApiResponse<ICommentResponse>> =>
  axiosInstance.patch(`/comment/${id}`, body);

export const likeComment = (id: number): Promise<IApiResponse<ICommentResponse>> => axiosInstance.patch(`/like/${id}`);

export const dislikeComment = (id: number): Promise<IApiResponse<ICommentResponse>> =>
  axiosInstance.patch(`/dislike/${id}`);

export const deleteComment = (id: number): Promise<IApiResponse<ICommentResponse>> =>
  axiosInstance.delete(`/comment/${id}`);

export const markCommentAsRead = (body: ICommentMarkAsReadRequest): Promise<IApiResponse<ICommentResponse>> =>
  axiosInstance.post("/comment/mark-as-read", body);

/**Text**/

export const storeText = (body: ITextAPI): Promise<IApiResponse<ITextResponse>> => axiosInstance.post("/text", body);

export const updateText = (id: number, body: ITextUpdateRequest): Promise<IApiResponse<ITextResponse>> =>
  axiosInstance.patch(`/text/${id}`, body);

/**User**/

export const updateUser = (body: FormData | Partial<IUser>): Promise<IApiResponse<IUserResponse>> => axiosInstance.post(`/user`, body);

//!IMPORTANT: take this request from api
//     -->  export const updateUserAvatar = (body:IUpdateUserRequest): Promise<IApiResponse<IUserResponse>> =>
//          axiosInstance.post(`/user`, body)

export const getUser = (): Promise<IApiResponse<IUserResponse>> => axiosInstance.get(`/user`);

export const getUserProfile = (id: number): Promise<IApiResponse<IUserResponse>> => axiosInstance.get(`/user/${id}`);

export const getUserComments = (page?: number): Promise<IApiResponse<IUserCommentsResponse>> =>
  axiosInstance.get(`/user/comments?page=${page}`);

export const getUserSubscriptions = (): Promise<IApiResponse<IUserResponse>> =>
  axiosInstance.get(`/user/subscriptions`);

export const followUser = (body: IUserFollowRequest): Promise<IApiResponse<IUserResponse>> =>
  axiosInstance.post(`/user/follow`, body);

export const searchUserByMention = (username: number): Promise<IApiResponse<IUserResponse[]>> =>
  axiosInstance.get(`/user/mentionable?username=${username}`);

/**Newsfeed**/

export const getNewsfeed = (): Promise<IApiResponse<INewsfeedResponse>> => axiosInstance.get(`/newsfeed`);

/**Notifications**/

export const markNotificationsAsRead = (
  body: INotificationMarkAsReadRequest
): Promise<IApiResponse<INotificationResponse>> => axiosInstance.post(`/notification/mark-as-read`, body);

export const getAllNotifications = (): Promise<IApiResponse<INotificationResponse>> =>
  axiosInstance.get(`/notification`);

/**Contact us**/

export const storeContacts = (body: IContactUsRequest): Promise<IApiResponse<unknown>> =>
  axiosInstance.post(`/contact-us`, body);

/**Web-category**/

export const getCategoryById = (id: number, page?: number): Promise<IApiResponse<ICategoryResponse>> =>
  axiosInstance.get(`/web-category/${id}?page=${page}`);

export const getCategoriesList = (): Promise<IApiResponse<{categories:ICategoriesListItem[]}>> =>
  axiosInstance.get(`/web-category/`);

/**Web-text**/
export const getTextsList = (page?: number): Promise<IApiResponse<ITextsResponse>> =>
  axiosInstance.get(`/web-text?page=${page}`);

export const getTextById = (id: number): Promise<IApiResponse<{ text: ITextResponseAPI }>> =>
  axiosInstance.get(`/web-text/${id}`);

/**Web-Global-Data**/
export const getGlobalData = (): Promise<IApiResponse<IGlobalDataResponse>> => axiosInstance.get(`/web-global-data`);

/**Web-Search**/

export const getSearchResults = ({
  searchPhrase,
  option,
  page,
}: ISearchRequest): Promise<IApiResponse<ISearchResponse>> => {
  return axiosInstance.get(`/web-search?search=${searchPhrase}&option=${option}&page=${page}`);
}

/**Web-comment**/
export const getCommentById = (id: number): Promise<IApiResponse<{ comment: ITextCommentAPI }>> =>
  axiosInstance.get(`/web-comment/${id}`);
