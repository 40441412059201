import './Terms.scss'
const Terms = () => {
  return (
    <div className="terms-wrapper">
      <div>
        <h1>Terms and Conditions for Comment8</h1>
        <span className='last-updated'>Last Updated: 12/5/2023</span>

        <h3>1. Introduction</h3>
        <p> Welcome to Comment8. By accessing our browser plugin, you agree to be bound by these
          Terms and Conditions. Please read them carefully.
        </p>

        <h3>2. User Account</h3>
        <p>
          To use Comment8, you may be required to create an
          account. You are responsible for maintaining the confidentiality of
          your account details and for all activities under your account
        </p>

        <h3>3. Use of the Service</h3>
        <p>
          You agree to use Comment8 only for lawful
          purposes and in a way that does not infringe on the rights of,
          restrict, or inhibit anyone else's use and enjoyment of the plugin.
        </p>

        <h3>4. Content Ownership and Intellectual Property</h3>
        <p>
          You retain ownership
          of your comments posted through Comment8 but grant us a license to
          use, reproduce, modify, and display them. The intellectual property
          of the service itself belongs to Comment8.
        </p>

        <h3>5. User Conduct and Obligations</h3>
        <p>
          You agree to adhere to our community
          guidelines and not to post any unlawful, harmful, or offensive
          content. You are also responsible for complying with all local,
          national, and international laws while using the service.
        </p>

        <h3>6. Termination and Suspension of Accounts</h3>
        <p>
          We reserve the right to
          suspend or terminate your account if you violate these Terms and
          Conditions or engage in illegal or harmful conduct.
        </p>

        <h3>7. Disclaimers and Limitations of Liability</h3>
        <p>
          Comment8 is provided "as
          is." We do not guarantee the accuracy or completeness of the
          information available through the service and exclude liability for
          any losses or damages arising from its use.
        </p>

        <h3>8. Indemnification</h3>
        <p>
          You agree to indemnify and hold harmless Comment8
          against any claims, damages, or expenses arising from your use of
          the service or your breach of these Terms.
        </p>

        <h3>9. Modifications to the Service and Terms</h3>
        <p>
          We reserve the right to
          modify or discontinue the service and may change these Terms and
          Conditions at any time. We will notify users of significant changes.
        </p>

        <h3>10. Governing Law and Dispute Resolution</h3>
        <p>
          These Terms and Conditions
          are governed by the laws of [Jurisdiction]. Any disputes will be
          resolved through arbitration in [Jurisdiction].
        </p>

        <h3>11. Contact Information</h3>
        <p>
          If you have any questions about these Terms and Conditions, please
          contact us at [Contact Information].
        </p>

        <h3>12. Miscellaneous</h3>
        <p>
          If any part of these Terms and Conditions is found
          to be invalid, the remaining parts will continue to be in effect.
          Failure by us to enforce any part of these Terms does not constitute
          a waiver of our right to do so later.
        </p>

      </div>
    </div>
  );
};
export default Terms
