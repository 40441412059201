import { Link, NavLink, useNavigate } from 'react-router-dom'
import notificationIcon from '../../../Assets/icons/notifications.svg'
import { Badge, Dropdown, Image, Nav } from 'react-bootstrap'
import defaultAvatar from '../../../Assets/icons/account_circle.svg'
import React from 'react'
import AuthService from '../../../Services/AuthService'
import './ProfileNav.scss'
import { HOME_PATH, MY_ACTIVITY_PATH, MY_PROFILE_PATH, NOTIFICATIONS_PATH } from '../../../utils/const'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { selectIsUnreadNotifications } from '../../../redux/notification.slice'

const ProfileNav = ({ hideOffcanvas, avatar }: { hideOffcanvas: () => void; avatar?: string }) => {

  const isUnreadNotifications = useAppSelector(selectIsUnreadNotifications)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const handleLogout = async () => {
    try {
      await AuthService.logout()
      localStorage.removeItem('token')
      dispatch({type:'logout'})
      navigate('/getaccess')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Link to={NOTIFICATIONS_PATH} className="d-none d-xl-block notification-wrapper">
        <Image src={notificationIcon} alt="notification icon" width={21} height={21} className={`notification-icon me-3`} />
        <Badge pill className={isUnreadNotifications ? 'unread' : undefined}></Badge>
      </Link>
      <Dropdown className="d-xl-block d-none text-start">
        <Dropdown.Toggle className="d-flex align-items-center cursor-pointer" id="dropdownUser">
          <Image
            src={avatar || defaultAvatar}
            className="object-fit-cover"
            width={32}
            height={32}
            roundedCircle
            alt="user-avatar"
          />
        </Dropdown.Toggle>
        <Dropdown.Menu align={{ xl: 'end' }} className="text-small mt-2" aria-labelledby="dropdownUser1">
          <Dropdown.Item as={NavLink} to={MY_PROFILE_PATH}>
            My Profile
          </Dropdown.Item>
          <Dropdown.Item as={NavLink} to={MY_ACTIVITY_PATH}>
            My Comments & Activity
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to={HOME_PATH} onClick={handleLogout}>
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      <Nav className="d-block d-xl-none">
        <Nav.Link as={NavLink} to={MY_PROFILE_PATH} onClick={hideOffcanvas}>
          My Profile
        </Nav.Link>
        <Nav.Link as={NavLink} to={MY_ACTIVITY_PATH} onClick={hideOffcanvas}>
          My Comments & Activity
        </Nav.Link>
        <Nav.Link as={NavLink} to={NOTIFICATIONS_PATH} onClick={hideOffcanvas}>
          Notifications
        </Nav.Link>
        <Nav.Link as={NavLink} to={HOME_PATH} onClick={handleLogout} className="pb-3">
          Logout
        </Nav.Link>
      </Nav>
    </>
  )
}
export default ProfileNav
