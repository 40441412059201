import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { ITextCommentAPI, ITextResponseAPI } from '../Interfaces/interfaces'
import { getTextById } from '../Api/api'

// Define a type for the slice state
interface TextState {
  text: ITextResponseAPI | null
  isFetching: boolean
}

// Define the initial state using that type
const initialState: TextState = {
  text: null,
  isFetching: false,
}

export const fetchTextById = createAsyncThunk('text/fetchTextById', async (id: number) => {
  const res = await getTextById(id)
  return res.data.text
})

export const textSlice = createSlice({
  name: 'text',
  initialState,
  reducers: {
    addTextComment: (state, action: PayloadAction<ITextCommentAPI>) => {
      if (state.text) {
        //check is that comment or reply
        if (action.payload.parent?.id) {
          const index = state.text.comments.findIndex((comment) => comment.id === action.payload.parent?.id)

          state.text.comments[index].replies
            ? state.text.comments[index].replies.push(action.payload)
            : (state.text.comments[index].replies = [action.payload])
          state.text.comments[index].repliesCount += 1
        } else {
          state.text.comments.unshift(action.payload)
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTextById.fulfilled, (state, action: PayloadAction<ITextResponseAPI>) => {
        state.text = action.payload
        state.isFetching = false
      })
      .addCase(fetchTextById.pending, (state) => {
        state.isFetching = true
      })
  },
})

export const { addTextComment } = textSlice.actions

export const selectText = (state: RootState) => state.text.text
export const selectIsTextFetching = (state: RootState) => state.text.isFetching

export default textSlice.reducer
