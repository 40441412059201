import { ChangeEvent } from "react";
import moment from "moment";

export const transformStringIntoUrlPath = (str: string) => {
  return str.toLowerCase().split(" ").join("-");
};

//take an image file, and a function that will create URL representing the object given image
export const onImageFileChange = (e: ChangeEvent<HTMLInputElement>, setImageUrl: (url: string) => void) => {
  if (e.target.files && e.target.files[0]) {
    const fileType = e.target.files[0].type.split("/")[0];
    //only image file acceptable
    if (fileType !== "image") {
      setImageUrl("");
      return (e.target.value = "");
    }
    setImageUrl(URL.createObjectURL(e.target.files[0]));
  }
};

export const getTime = (date: string) => {
  let result = moment(date).fromNow();
  const now = moment();
  const days = now.diff(date, "days");
  const weeks = now.diff(date, "weeks");
  if (days > 0) {
    if (days === 1) {
      result = `Yesterday`;
    } else if (days < 7) {
      result = `${days} days ago`;
    } else if (days > 13 && days <= 25) {
      result = `${weeks} weeks ago`;
    }
  } else {
    result = "Today";
  }

  return result;
};
