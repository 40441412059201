import React, { useState } from 'react'
import { CategoryWithPath } from '../Header'
import { NavLink } from 'react-router-dom'
import './CategoryItem.scss'
import { Nav, Dropdown, Accordion, Stack } from 'react-bootstrap'

const CategoryItem = ({ category, onClick }: { category: CategoryWithPath; onClick: () => void }) => {
  const [isSubCategoryShow, setIsSubCategoryShow] = useState<boolean>(false)

  function renderSubcategories(subCategories: CategoryWithPath[]) {
    return subCategories.map((subCategory) => (
      <Dropdown.Item as='div' style={{ width: 'min-content', backgroundColor: 'transparent' }} key={subCategory.id}>
        <Accordion flush>
          <Accordion.Item eventKey={subCategory.id.toString()}>
            <Accordion.Header>
              <Nav.Link as={NavLink} onClick={onClick} to={`/${subCategory.path}`} state={{ id: subCategory.id }}>
                {subCategory.name}
              </Nav.Link>
            </Accordion.Header>
          </Accordion.Item>
        </Accordion>
      </Dropdown.Item>
    ))
  }

  function renderSubcategoriesDropdown(subCategories: CategoryWithPath[]) {
    return subCategories.map((subCategory) => (
      <Dropdown.Item as='div' key={subCategory.id} className="subcategory d-flex align-items-center py-2">
        <NavLink
          onClick={onClick}
          className={(nav) => (nav.isActive ? 'nav-link active' : 'nav-link')}
          to={`/${subCategory.path}`}
          state={{ id: subCategory.id }}
        >
          {subCategory.name}
        </NavLink>
        <Dropdown.Toggle className={!subCategory.children.length ? 'd-none' : undefined} />
        <Dropdown.Menu show={true} className="d-none overflow-hidden">
          {renderSubcategoriesDropdown(subCategory.children)}
        </Dropdown.Menu>
      </Dropdown.Item>
    ))
  }

  return (
    <Nav.Item className={`category-link position-relative`}>
      <Stack direction="horizontal">
        <Nav.Link as={NavLink} onClick={onClick} to={`/${category.path}`} state={{ id: category.id }}>
          {category.name}
        </Nav.Link>
        <Dropdown.Toggle
          className={`${category.children.length === 0 && 'd-none'} d-xl-none`}
          onClick={() => setIsSubCategoryShow(!isSubCategoryShow)}
        />
      </Stack>
      <Dropdown.Menu show={isSubCategoryShow} className="border-0 p-0">
        {renderSubcategories(category.children)}
      </Dropdown.Menu>
      <Dropdown.Menu
        show={true}
        style={{ display: 'block' }}
        className={`${
          category.children.length === 0 && 'visually-hidden'
        } subcategory-dropdown d-none position-absolute`}
      >
        {renderSubcategoriesDropdown(category.children)}
      </Dropdown.Menu>
    </Nav.Item>
  )
}
export default CategoryItem
