import { Link, useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import Comments from '../../Components/Comments/Comments'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { addTextComment, fetchTextById, selectIsTextFetching, selectText } from '../../redux/text.slice'
import Loader from '../../Components/Loader/Loader'
import './TextPage.scss'
import { Button, Card, Image, Stack } from 'react-bootstrap'
import iconBack from '../../Assets/icons/arrow-previous-left-icon.svg'
import iconExternalLink from '../../Assets/icons/Icon-externalLink.svg'
import { pusher } from '../../Api/sockets'
import { ITextCommentAPI } from '../../Interfaces/interfaces'

const TextPage = () => {
  const dispatch = useAppDispatch()
  const text = useAppSelector(selectText)
  const isFetching = useAppSelector(selectIsTextFetching)
  const { id } = useParams()
  const navigate = useNavigate()
  const [newCommentsIds, setNewCommentsIds] = useState<number[]>([])

  useEffect(() => {
    const channel = pusher.subscribe(`web_comment_on_text_${id}`)
    channel.bind(`App\\Events\\RealTimeWebComment`, (comment: { data: ITextCommentAPI }) => {
      dispatch(addTextComment(comment.data))
      setNewCommentsIds((prev) => [...prev, comment.data.id])
      setTimeout(() => {
        setNewCommentsIds((prev) => prev.filter((id) => id !== comment.data.id))
      }, 2250)
    })
    if (id) {
      dispatch(fetchTextById(+id))
    }
    return () => {
      channel.unbind(`App\\Events\\RealTimeWebComment`)
      pusher.unsubscribe(`web_comment_on_text_${id}`)
    }
  }, [id])
  if (!text || isFetching) return <Loader />
  return (
    <div id="text-page">
      <Button onClick={()=>navigate(-1)} className="back-btn px-3">
        <Image width={20} height={20} src={iconBack} alt="" />
      </Button>

      <Card className="rounded-4 p-3">
        <Stack className="position-relative">
          <Image width={100} height={100} src={text.favicon} roundedCircle alt="" />
          <Card.Body className="text-break">
            <Card.Title className="fs-4 mb-2">{text.title}</Card.Title>
            <Card.Text className="">{text.content}</Card.Text>
          </Card.Body>
          <Stack gap={2} className="position-absolute top-0 end-0 py-2">
            <Link to="/" className="btn btn-orange">
              All comments
            </Link>
            <Link
              target="_blank"
              className="btn btn-orange d-flex gap-1 align-items-center justify-content-center"
              to={text.url}
            >
              <span>Open</span>
              <img className="align-self-center" width={19} height={19} src={iconExternalLink} alt="" />
            </Link>
          </Stack>
        </Stack>
      </Card>

      <Comments newCommentsIds={newCommentsIds} comments={text.comments!} />
    </div>
  )
}
export default TextPage
