import React, { FC, useEffect, useState } from 'react'
import './ResetPassword.scss'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import ModalSuccess from '../../Components/ModalSuccess/ModalSuccess'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import AuthService from '../../Services/AuthService'

const ResetPasswordSchema = Yup.object().shape({
  password: Yup.string().min(5, 'Password is too short').required('Required field'),
  password_confirmation: Yup.string()
    .test('passwords match', 'Passwords do not match', function () {
      return this.parent.password === this.parent.password_confirmation
    })
    .required('Required field'),
})

const ResetPassword: FC = () => {
  const [query] = useSearchParams()
  const token = query.get('token')
  const email = query.get('email')
  const [canReset, setCanReset] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    setIsLoading(true)
    email && token &&
      AuthService.isTokenAlive(email,token)
        .then((res) => {
          setCanReset(res.success)
        })
        .catch((e) => {})
        .finally(() => {
          setIsLoading(false)
        })
  }, [])

  const [isModalShow, setIsModalShow] = useState<boolean>(false)
  const navigate = useNavigate()
  if (!email || !token) return <Navigate to="/" />

  if (!canReset && !isLoading)
    return (
      <div className="expiration-message">
        <div className="text-center">
          <p className="mb-4">Sorry,this link has been expired</p>
          <Link className="btn btn-orange" to="/">
            Go to main
          </Link>
        </div>
      </div>
    )
  if(isLoading)return <></>
  return (
    <>
      <div className="container py-5">
        {isModalShow && <ModalSuccess isModalShow={isModalShow}>Password changed successfully</ModalSuccess>}
        <div className="row flex-wrap align-items-center">
          <Formik
            initialValues={{ password: '', password_confirmation: '' }}
            validationSchema={ResetPasswordSchema}
            onSubmit={async (values, { setFieldError, resetForm }) => {
              try {
                await AuthService.resetPassword(email, token, values.password, values.password_confirmation)
                setIsModalShow(true)
                setTimeout(() => {
                  setIsModalShow(false)
                  navigate('/')
                }, 2000)
                resetForm()
              } catch (error) {
                navigate('/')
              }
            }}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form className="col-lg-12">
                <div className="fields d-grid justify-content-center">
                  <h1 className="fs-1 mb-4 text-center">Reset password</h1>
                  <label className="item-label item-row mb-4">
                    <p className="field-title fs-5 mb-3">New Password</p>
                    <Field
                      onPaste={(e: KeyboardEvent) => e.preventDefault()}
                      value={values.password}
                      onChange={handleChange}
                      type="password"
                      name="password"
                      placeholder="Enter your new password"
                      className={`${errors.password && touched.password ? 'error' : ''} field mb-1`}
                    />
                    <ErrorMessage name="password" component="div" className="field-error" />
                  </label>
                  <label className="item-label item-row mb-4">
                    <p className="field-title fs-5 mb-3">New Password Confirmation</p>
                    <Field
                      onPaste={(e: KeyboardEvent) => e.preventDefault()}
                      value={values.password_confirmation}
                      onChange={handleChange}
                      type="password"
                      name="password_confirmation"
                      placeholder="Confirm new password"
                      className={`${
                        errors.password_confirmation && touched.password_confirmation ? 'error' : ''
                      } field mb-1`}
                    />
                    <ErrorMessage name="password_confirmation" component="div" className="field-error" />
                  </label>
                  <div className="buttons-wrap mb-5">
                    <button type="submit" className="btn-auth mx-auto">
                      Reset
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
