import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { ICategoriesListItem } from '../Interfaces/interfaces'
import { getCategoriesList } from '../Api/api'

interface CategoryState {
  categoriesList: ICategoriesListItem[]
}

const initialState: CategoryState = {
  categoriesList: [],
}

export const fetchCategoriesList = createAsyncThunk('category/fetchCategoriesList', async () => {
  const res = await getCategoriesList()
  return res.data.categories
})

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategoriesList.fulfilled, (state, action: PayloadAction<ICategoriesListItem[]>) => {
      state.categoriesList = action.payload
    })
  },
})

export const selectCategoriesList = (state: RootState) => state.category.categoriesList

export default categorySlice.reducer
