import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios';

const baseURL: string = process.env.REACT_APP_API_URL || 'https://staging-api.comment8.heraldapp.pw/api';

const instance: AxiosInstance = axios.create({
    baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use((instance) => {
    const token = localStorage.getItem("token")

    if (token) {
        instance.headers.Authorization = `Bearer ${token}`;
    }
    return instance;
}, error => {
    Promise.reject(error)
});

instance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response.data;
    },
    (error: AxiosError) => {
        if (error.response) {
            console.error('Response error:', error.response.status, error.response.data);
        } else if (error.request) {
            console.error('No response from server:', error.request);
        } else {
            console.error('Error:', error.message);
        }
        return Promise.reject(error);
    }
);

export default instance;
