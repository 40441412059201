import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { fetchComment, selectComment, selectIsCommentFetching } from '../../redux/comment.slice'
import Loader from '../../Components/Loader/Loader'
import CommentItem from '../../Components/Comments/CommentItem/CommentItem'
import { Button, Image, Stack } from 'react-bootstrap'
import iconBack from '../../Assets/icons/arrow-previous-left-icon.svg'
import './Tread.scss'

const Tread = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const comment = useAppSelector(selectComment)
  const isFetching = useAppSelector(selectIsCommentFetching)
  useEffect(() => {
    id && dispatch(fetchComment(+id))
  }, [id])
  if (isFetching || !comment) return <Loader />
  return (
    <>
      <Button onClick={() => navigate(-1)} className="back-btn px-3">
        <Image width={20} height={20} src={iconBack} alt="" />
      </Button>

      <CommentItem comment={comment} />
      <Stack gap={2} className="mt-2 replies">
        {comment.replies.map((reply) => (
          <CommentItem comment={reply} />
        ))}
      </Stack>
    </>
  )
}
export default Tread
