export const MY_PROFILE_PATH = '/my-profile'
export const MY_ACTIVITY_PATH = '/my-comments-and-activity'
export const FOLLOWERS_PATH = '/followers-and-followings'
export const NOTIFICATIONS_PATH = '/notifications'
export const HOME_PATH = '/'
export const SOURCE_PAGE_PATH = '/comment'
export const PUBLIC_PROFILE_PATH = '/profile'
export const SEARCH_RESULTS_PATH = '/search-results'
export const PRIVACY_POLICY_PATH = '/privacy-policy'
export const TERMS_OF_SERVICE_PATH = '/terms-of-service'
export const CONTACT_US_PATH = '/contact-us'
export const TREAD_PATH = '/tread'
