import { IUser } from '../../../Interfaces/interfaces'
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react'
import defaultAvatar from '../../../Assets/icons/account_circle_black.svg'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import '../ProfileComponents.scss'
import { updateUser } from '../../../Api/api'
import { useAppDispatch } from '../../../redux/store'
import { setUser } from '../../../redux/user.slice'
import { CropProfilePhoto } from '../CropProfilePhoto/CropProfilePhoto'

type Props = IUser

const UserSchema = Yup.object().shape({
  name: Yup.string().required('Name cannot be empty').max(100, 'Name is too long'),
  username: Yup.string().required('Username cannot be empty').max(100, 'Name is too long'),
  email: Yup.string().email('Please enter a valid email'),
  biography: Yup.string().max(1000, 'Too long'),
  password: Yup.string().test('password validation', 'Password is too short', (value) => {
    return value ? (value.length === 0 ? true : value.length >= 8) : true
  }),
})

const EditProfile: FC<Props> = (user) => {
  const [photoCrop, setPhotoCrop] = useState<boolean>(false)
  const [profileImageUrl, setProfileImageUrl] = useState(user.avatar)
  const [profileFile, setProfileFile] = useState<File | null>(null)
  const [isModalShow, setIsModalShow] = useState<boolean>(false)
  const [onFileSelectError, setOnFileSelectError] = useState<{
    error: string | boolean
  }>()

  const formik = useFormik({
    initialValues: {
      name: user.name,
      username: user.username,
      email: user.email,
      biography: user.biography,
      password: '',
      ['web-context']: '', //back-end needs this value
    },
    validationSchema: UserSchema,
    onSubmit: (values, { resetForm }) => {
      const { password, ...profileToUpdate } = values
      updateUser(password.length > 0 ? values : profileToUpdate).then((res) => {
        dispatch(setUser(res.data.user))
      })
    },
  })
  const { handleSubmit, values, errors, touched, isValid, dirty, handleChange, handleBlur } = formik

  const handleFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0]
      const { size, type } = file
      if (size > 16000000) {
        setOnFileSelectError((prev) => {
          return { ...prev, error: 'File size cannot exceed more than 16MB' }
        })
      } else if (type !== 'image/jpg' && type !== 'image/jpeg' && type !== 'image/png') {
        setOnFileSelectError((prev) => {
          return { ...prev, error: 'File type must be an image' }
        })
      } else {
        setOnFileSelectError((prev) => {
          return { ...prev, error: '' }
        })
        setPhotoCrop(true)
        setProfileFile(file)
      }
    }
  }

  const dispatch = useAppDispatch()

  useEffect(() => {
    const handleUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault()
      e.returnValue = ''
      formik.submitForm()
      return 'You have unsaved changes'
    }
    if (formik.dirty) window.addEventListener('beforeunload', handleUnload)
    return () => {
      window.removeEventListener('beforeunload', handleUnload)
      formik.submitForm()
    }
  }, [formik.dirty])

  return (
    <>
      {/*{isModalShow && <ModalSuccess isModalShow={isModalShow}>Profile successfully changed</ModalSuccess>}*/}
      <form onSubmit={handleSubmit}>
        <div id="editProfile" className="row align-items-start">
          {/*Avatar*/}
          <div className="col-12 mb-3 userInfo">
            <div className="card rounded-4 p-2">
              <div className="d-flex flex-column align-items-center">
                {/**/}
                <img
                  src={profileImageUrl || defaultAvatar}
                  alt=""
                  className="rounded-circle object-fit-cover"
                  height="250"
                  width="250"
                />
                {photoCrop && profileFile && (
                  <CropProfilePhoto
                    profilePhoto={profileFile}
                    newImage={(url, blob) => {
                      if (url === '') setPhotoCrop(false)
                      else {
                        setProfileFile(new File([blob], 'avatar'))
                        setProfileImageUrl(url)
                        setPhotoCrop(false)
                      }
                    }}
                  />
                )}

                <label htmlFor="avatar">
                  <span className="btn">Upload image</span>
                  <input
                    onClick={(e: React.MouseEvent<HTMLInputElement>) => (e.currentTarget.value = '')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleFileInput(e)
                      handleChange(e)
                    }}
                    id="avatar"
                    type="file"
                    name="avatar"
                    className="d-none"
                  />
                </label>
                {/*Name input*/}
                <label className="item-label" htmlFor="name">
                  <input
                    value={values.name || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    className={`${errors.name ? 'error' : ''} field`}
                  />
                  {errors.name && <div className="field-error">{errors.name}</div>}
                </label>
                {/*Username input*/}
                <label className="item-label" htmlFor="username">
                  <input
                    value={values.username || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    name="username"
                    placeholder="Enter your username"
                    className={`${errors.username ? 'error' : ''} field  text-secondary`}
                  />
                  {errors.username && <div className="field-error">{errors.username}</div>}
                </label>
                {/*Email input*/}
                <label className="item-label" htmlFor="email">
                  <input
                    value={values.email || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    className={`${errors.email ? 'error' : ''} field  `}
                  />
                  {errors.email && <div className="field-error">{errors.email}</div>}
                </label>
                <label className="item-label" htmlFor="password">
                  <input
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="password"
                    name="password"
                    placeholder="New password"
                    className={`${errors.password ? 'error' : ''} field  `}
                  />
                  {errors.password && <div className="field-error">{errors.password}</div>}
                </label>
              </div>
            </div>
          </div>
          {/**/}

          <div className="col-12  biography">
            <h1 className="mb-2">Biography</h1>
            <label className="item-label" htmlFor="name">
              <textarea
                style={{ height: 350 }}
                value={values.biography || ''}
                onChange={handleChange}
                name="biography"
                placeholder="Enter your biography"
                className={`${errors.biography ? 'error' : ''} field  textarea rounded-4`}
              />
              {errors.biography && <div className="field-error">{errors.biography}</div>}
            </label>
          </div>
        </div>
      </form>
    </>
  )
}
export default EditProfile
