import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { IUser } from '../Interfaces/interfaces'
import { getUser } from '../Api/api'
import { fetchNotifications } from './notification.slice'
import { fetchCategoriesList } from './category.slice'

// Define a type for the slice state
interface UserState {
  user: IUser | null
  isFetching: boolean
}

// Define the initial state using that type
const initialState: UserState = {
  user: null,
  isFetching: false,
}

export const fetchUser = createAsyncThunk('user/fetchUser', async (_,{dispatch}) => {
  const res = await getUser()
  await dispatch(fetchNotifications())
  await dispatch(fetchCategoriesList())
  return res.data.user
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.fulfilled, (state, action: PayloadAction<IUser>) => {
        state.user = action.payload
        state.isFetching = false
      })
      .addCase(fetchUser.pending, (state) => {
        state.isFetching = true
      })
      .addCase(fetchUser.rejected, () => {})
  },
})

export const { setUser } = userSlice.actions

export const selectUser = (state: RootState) => state.user.user
export const selectUserIsFetching = (state: RootState) => state.user.isFetching

export default userSlice.reducer
