import React, { useEffect, useState } from 'react'
import { getUserComments } from '../../Api/api'
import EditProfile from '../../Components/ProfileComponents/EditProfile/EditProfile'
import { useAppSelector } from '../../redux/store'
import { selectUser } from '../../redux/user.slice'
import { Link } from 'react-router-dom'
import Loader from '../../Components/Loader/Loader'
import { Image, Stack } from 'react-bootstrap'
import eyeIcon from '../../Assets/images/eye-icon.png'
import './Profile.scss'
import { FOLLOWERS_PATH, PUBLIC_PROFILE_PATH } from '../../utils/const'

const Profile = () => {
  const [isFetching, setIsFetching] = useState<boolean>(true)
  useEffect(() => {
    setIsFetching(true)
    getUserComments().then((res) => res.data.comments)
    setIsFetching(false)
  }, [])
  const user = useAppSelector(selectUser)
  if (!user || isFetching) return <Loader />
  return (
    <>
      {/*Breadcrumb*/}
      <nav className="my-2">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/packages/web-app/public">Home</a>
          </li>
          <li className="breadcrumb-item active">My Profile</li>
        </ol>
      </nav>
      {/*Breadcrumb*/}
      <EditProfile {...user} />
      <Stack
        as={Link}
        to={PUBLIC_PROFILE_PATH + '/' + user.id}
        className="btn btn-orange preview-profile"
        direction="horizontal"
        gap={1}
      >
        Preview Profile
        <Image style={{ filter: 'invert(1)' }} height={18} width={18} src={eyeIcon} />
      </Stack>
      <Link to={FOLLOWERS_PATH}>
        <div className="text-center fs-4 mt-4">Followers & Followings</div>
      </Link>
    </>
  )
}
export default Profile
