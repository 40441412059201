import './Loader.scss'
import { Spinner } from 'react-bootstrap'

const Loader = () => {
  return (
    <>
      <div className="spinner-border-wrapper">
        <Spinner variant="border" />
      </div>
    </>
  )
}
export default Loader
