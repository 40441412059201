import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { INotification } from '../Interfaces/interfaces'
import { INotificationResponse } from '../Interfaces/responceInterfaces'
import { getAllNotifications, markNotificationsAsRead } from '../Api/api'

// Define a type for the slice state
interface NotificationState {
  notifications: INotificationResponse | null
  isFetching: boolean
}

// Define the initial state using that type
const initialState: NotificationState = {
  notifications: null,
  isFetching: false,
}

export const fetchNotifications = createAsyncThunk('notification/fetchNotifications', async () => {
  const res = await getAllNotifications()
  return res.data
})

export const readNotifications = createAsyncThunk(
  'notification/sendReadNotificationToServer',
  async ({ id, group }: { id: number; group: string }) => {
    await markNotificationsAsRead({ notifications: [id] })
    return { id, group }
  }
)

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<INotification>) => {
      if (state.notifications && state.notifications.notifications.data) {
        const dateKey = new Date(action.payload.created_at).toISOString().split('T')[0]
        if (state.notifications.notifications.data[dateKey]) {
          state.notifications.notifications.data[dateKey].unshift(action.payload)
        } else {
          state.notifications.notifications.data = Object.assign(
            { [dateKey]: [action.payload] },
            state.notifications.notifications.data
          )
          // state.notifications.notifications.data[dateKey] = [action.payload]
          state.notifications.notifications.total_count = state.notifications.notifications.total_count
            ? state.notifications.notifications.total_count + 1
            : 1
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.fulfilled, (state, action: PayloadAction<INotificationResponse>) => {
        state.notifications = action.payload
        state.isFetching = false
      })
      .addCase(fetchNotifications.pending, (state) => {
        state.isFetching = true
      })
      .addCase(readNotifications.fulfilled, (state, action: PayloadAction<{ id: number; group: string }>) => {
        if (
          state.notifications &&
          state.notifications.notifications.data &&
          state.notifications.notifications.data[action.payload.group]
        ) {
          state.notifications.notifications.data[action.payload.group] = state.notifications.notifications.data[
            action.payload.group
          ].map((notification) => {
            if (notification.id === action.payload.id) return { ...notification, read: true }
            return notification
          })
        }
      })
  },
})

export const { addNotification } = notificationSlice.actions

export const selectNotifications = (state: RootState) => state.notification.notifications
export const selectIsNotificationsFetching = (state: RootState) => state.notification.isFetching

export const selectIsUnreadNotifications = createSelector(selectNotifications, (notifications) => {
  if(notifications && notifications.notifications.data)
    return !Object.values(notifications.notifications.data)
      .flat()
      .every(notificationItem=>notificationItem.read)
  return false
})

export default notificationSlice.reducer
