import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IPagination, ITextResponseAPI } from '../Interfaces/interfaces'
import { getCategoryById, getTextsList } from '../Api/api'
import { RootState } from './store'

// Define a type for the slice state
interface SourcesState {
  categoryName: string
  textsList: ITextResponseAPI[]
  isFetching: boolean
  pagination: IPagination | null
}

// Define the initial state using that type
const initialState: SourcesState = {
  categoryName: '',
  textsList: [],
  isFetching: false,
  pagination: null,
}

async function getSources({ id, page }: { id?: number; page?: number }) {
  if (id) {
    const res = await getCategoryById(id, page)
    return {
      categoryName: res.data.category.name,
      textsList: res.data.category.texts.data,
      pagination: res.data.category.texts.pagination,
    }
  } else {
    const res = await getTextsList(page)
    return {
      categoryName: '',
      textsList: res.data.texts,
      pagination: res.data.pagination,
    }
  }
}

export const getAllSources = createAsyncThunk('sources/fetchAllTexts', getSources)
export const getMoreSources = createAsyncThunk('sources/getMoreSources', getSources)

export const sourcesSlice = createSlice({
  name: 'sources',
  initialState,
  reducers: {
    addSource:(state,action:PayloadAction<ITextResponseAPI>)=>{
      state.textsList.unshift(action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSources.fulfilled, (state, action: PayloadAction<Omit<SourcesState, 'isFetching'>>) => {
        state.textsList = action.payload.textsList
        state.categoryName = action.payload.categoryName
        state.pagination = action.payload.pagination
        state.isFetching = false
      })
      .addCase(getAllSources.pending, (state) => {
        state.isFetching = true
      })
      .addCase(getMoreSources.fulfilled, (state, action: PayloadAction<Omit<SourcesState, 'isFetching'>>) => {
        state.textsList.push(...action.payload.textsList)
        state.pagination = action.payload.pagination
      })
  },
})

export const {addSource} = sourcesSlice.actions

export const selectSourcesTexts = (state: RootState) => state.sources.textsList
export const selectSourcesCategoryName = (state: RootState) => state.sources.categoryName
export const selectSourcesIsFetching = (state: RootState) => state.sources.isFetching
export const selectSourcesPagination = (state: RootState) => state.sources.pagination

export default sourcesSlice.reducer
