import React from "react";
import "./ModalSuccess.scss";

const ModalSuccess = ({ isModalShow = false, children }: { isModalShow: boolean; children: React.ReactNode }) => {
  return (
    <>
      <div className={`app-notification-container ${isModalShow && "show"}`}>
        <div className="app-notification-message-container">
          <span className="app-notification-message ">{children}</span>
          <svg className="checkmark_notification" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle_notification" cx="26" cy="26" r="25" fill="none" />
            <path
              className="checkmark__check_notification"
              fill="none"
              d="M14.1 27.2l7.1 7.2 16.7-16.8"
              strokeWidth="4"
            />
          </svg>
        </div>
      </div>
    </>
  );
};
export default ModalSuccess;
