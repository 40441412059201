import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import LinesEllipsis from 'react-lines-ellipsis'
import { ICategory, ITextCommentAPI, ITextResponseAPI, IUser } from '../../../Interfaces/interfaces'
import defaultAvatar from '../../../Assets/icons/account_circle_black.svg'
import { transformStringIntoUrlPath } from '../../../utils/utils'
import './SearchResults.scss'
import { SearchOptions, SearchResult } from '../SearchBar'
import { PUBLIC_PROFILE_PATH, SOURCE_PAGE_PATH } from '../../../utils/const'

type Props = {
  keepFocus: () => void
  hidden: boolean
  results: SearchResult[]
  activeTab: SearchOptions
  showAll: () => void
}

const SearchResults: FC<Props> = ({ keepFocus, hidden, results, activeTab, showAll }) => {
  return (
    <div onClick={keepFocus} className={`search-results ${hidden && 'hidden'} d-none d-xl-block`}>
      {<div className={`${results.length !== 0 && 'd-none'} item item-no-results`}>No matches find...</div>}
      {results.slice(0, 4).map((result) => {
        switch (activeTab) {
          case 'texts': {
            let text = result as ITextResponseAPI
            return (
              <SearchResultItem
                key={result.id}
                title={text.title}
                image={text.favicon}
                content={text.content || ''}
                link={`${SOURCE_PAGE_PATH}/${text.id}`}
              />
            )
          }
          case 'comments': {
            let comment = result as ITextCommentAPI
            return (
              <SearchResultItem
                key={result.id}
                title={comment.user.name}
                image={comment.user.avatar || defaultAvatar}
                content={comment.content || ''}
                link={`${SOURCE_PAGE_PATH}/${comment.text.id}#${comment.id}`}
                linkState={comment.parent?.id}
              />
            )
          }
          case 'users': {
            let user = result as IUser
            return (
              <SearchResultItem
                key={result.id}
                title={user.name}
                image={user.avatar || defaultAvatar}
                content={user.biography || 'No biography'}
                link={PUBLIC_PROFILE_PATH + '/' + user.id}
              />
            )
          }
          case 'categories': {
            let category = result as ICategory
            return (
              <Link
                key={result.id}
                style={{ textDecoration: 'none', color: 'inherit' }}
                to={'/' + transformStringIntoUrlPath(category.name)}
                state={{ id: category.id }}
              >
                <div className="item">{category.name}</div>
              </Link>
            )
          }
          default:
            break
        }
      })}
      {results.length > 4 && (
        <button onClick={showAll} className="show-all">
          Show all
        </button>
      )}
    </div>
  )
}
export default SearchResults

const SearchResultItem = ({
  image,
  title,
  content,
  link,
  linkState,
}: {
  image?: string
  title: string
  content: string
  link: string
  linkState?: number
}) => (
  <Link style={{ textDecoration: 'none', color: 'inherit' }} to={link} state={{ id: linkState }}>
    <div className="item">
      <div className="info">
        <img src={image} width="40" height="40" alt="" />
      </div>
      <div className="item-content">
        <p className="text-truncate">{title}</p>
        <LinesEllipsis className="text-break" text={content} ellipsis="..." maxLine={3} />
      </div>
    </div>
  </Link>
)
