import React, { useEffect, useState } from 'react'
import './Notifications.scss'
import { INotification } from '../../Interfaces/interfaces'
import { getTime } from '../../utils/utils'
import Loader from '../../Components/Loader/Loader'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import {
  fetchNotifications,
  readNotifications,
  selectIsNotificationsFetching,
  selectNotifications,
} from '../../redux/notification.slice'
import NotificationItem from '../../Components/NotificationItem/NotificationItem'
import { pusher } from '../../Api/sockets'

const Notifications = () => {
  const dispatch = useAppDispatch()
  const notifications = useAppSelector(selectNotifications)
  const isFetching = useAppSelector(selectIsNotificationsFetching)
  const [newNotificationsIds, setNewNotificationsIds] = useState<number[]>([])
  const readNotificationItem = (id: number, group: string) => {
    dispatch(readNotifications({ id, group }))
  }

  useEffect(() => {
    dispatch(fetchNotifications())
    pusher.bind(`App\\Events\\RealTimeNotificationEvent`, (notification: { data: INotification }) => {
      setNewNotificationsIds((prev) => [...prev, notification.data.id])
      setTimeout(()=>{
        setNewNotificationsIds((prev) => prev.filter((id) => id !== notification.data.id))
      },2250)
    })
    return () => {
      pusher.unbind(`App\\Events\\RealTimeNotificationEvent`)
    }
  }, [])
  console.log(newNotificationsIds)
  if (isFetching) return <Loader />
  return (
    <>
      <h1 style={{ marginBottom: '-2rem' }} className="fs-1 text-center ">
        Notifications
      </h1>
      <div className="notifications-list mt-5">
        {notifications &&
          notifications.notifications.data &&
          Object.keys(notifications.notifications.data).map((notificationKey: string) => {
            if (notifications.notifications.data) {
              const notificationsItems: INotification[] = notifications.notifications.data[notificationKey]
              return (
                <div key={notificationKey} className="period-container d-flex flex-column">
                  <span className="period-title">{getTime(notificationKey)}</span>

                  <div className="d-flex flex-column">
                    {notificationsItems.map((notification) => (
                      <div className={newNotificationsIds.includes(notification.id) ? 'blinkAnimation' : undefined}>
                        <NotificationItem
                          key={notification.id}
                          notification={notification}
                          notificationGroup={notificationKey}
                          makeRead={(id: number, group: string) => readNotificationItem(id, group)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )
            }
          })}
      </div>
    </>
  )
}

export default Notifications
