import React from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.scss'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import { CONTACT_US_PATH, PRIVACY_POLICY_PATH, TERMS_OF_SERVICE_PATH } from '../../utils/const'

function Footer() {
  return (
    <footer className="d-flex flex-wrap justify-content-between align-items-center py-3">
      <Container>
        <Row className="gap-md-0 gap-3">
          <Col md={4}>
            <Nav.Link as={NavLink} to={PRIVACY_POLICY_PATH}>Privacy Policy</Nav.Link>
          </Col>
          <Col md={4} className="text-md-center">
            <Nav.Link as={NavLink} to={TERMS_OF_SERVICE_PATH}>Terms of Service</Nav.Link>
          </Col>
          <Col md={4} className="text-md-end">
            <Nav.Link as={NavLink} to={CONTACT_US_PATH}>Contact Us</Nav.Link>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
