import { Link, useSearchParams } from 'react-router-dom'
import React, { useCallback, useEffect, useState } from 'react'
import SourceItems from '../../Components/SourceItems/SourceItems'
import { useDebouncedCallback } from 'use-debounce'
import { ICategory, IPagination, ITextCommentAPI, ITextResponseAPI, IUser } from '../../Interfaces/interfaces'
import { getSearchResults } from '../../Api/api'
import CommentItem from '../../Components/Comments/CommentItem/CommentItem'
import defaultAvatar from '../../Assets/icons/account_circle_black.svg'
import { transformStringIntoUrlPath } from '../../utils/utils'
import Loader from '../../Components/Loader/Loader'
import { PUBLIC_PROFILE_PATH, SOURCE_PAGE_PATH } from '../../utils/const'
import { Card } from 'react-bootstrap'

const SearchResults = () => {
  const [query] = useSearchParams()
  const searchPhrase = query.get('search')
  const searchOption = query.get('option')

  const [hasMore, setHasMore] = useState<boolean>(true)

  const [results, setResults] = useState<(ITextResponseAPI | IUser | ITextCommentAPI | ICategory)[]>([])
  const [pagination, setPagination] = useState<IPagination>()
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const loadMore = useCallback(() => {
    searchPhrase &&
      pagination &&
      getSearchResults({ searchPhrase, option: searchOption as any, page: pagination.currentPage + 1 }).then((res) => {
        res.data.search.length === 0 && setHasMore(false)
        setResults([...results, ...res.data.search])
        setPagination(res.data.pagination)
      })
  }, [pagination?.currentPage])

  const debouncedSearch = useDebouncedCallback(() => {
    searchPhrase &&
      getSearchResults({ searchPhrase, option: searchOption as any })
        .then((res) => {
          setResults(res.data.search)
          setPagination(res.data.pagination)
        })
        .finally(() => setIsFetching(false))
  }, 300)

  useEffect(() => {
    setIsFetching(true)
    setResults([])
    debouncedSearch()
  }, [searchPhrase, searchOption])

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement
      if (scrollHeight - scrollTop - clientHeight < 200 && hasMore) {
        loadMore()
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [loadMore])
  if (isFetching) return <Loader />
  return (
    <div style={{ marginTop: '1.5rem' }}>
      {searchOption === 'texts' && results.length > 0 && (
        <div className="">
          <SourceItems texts={results as ITextResponseAPI[]} />
        </div>
      )}
      {searchOption === 'comments' && results.length > 0 && (
        <div className=" d-grid flex-wrap gap-2">
          {(results as ITextCommentAPI[])?.map((comment) => (
            <Link
              className="link-dark text-decoration-none"
              to={`${SOURCE_PAGE_PATH}/${comment.text.id}#${comment.id}`}
            >
              <CommentItem key={comment.id} comment={comment} />
            </Link>
          ))}
        </div>
      )}
      {searchOption === 'users' && results.length > 0 && (
        <div className=" d-grid d-sm-flex flex-wrap gap-2">
          {(results as IUser[]).map((user) => (
              <Card
                to={PUBLIC_PROFILE_PATH+'/'+user.id}
                as={Link} key={user.id} className="col-lg-4 col-sm-12 d-grid">
                <div className="d-flex justify-content-center pt-3">
                  <img
                    width={100}
                    height={100}
                    className="rounded-circle object-fit-cover"
                    src={user.avatar || defaultAvatar}
                    alt=""
                  />
                </div>
                <div className="card-body text-center fs-5 text-truncate">
                  <h3 className="card-title text-truncate">{user.name}</h3>
                  <h3 className="card-subtitle text-muted text-truncate">{user.username || 'No username'}</h3>
                  <h3 className="text-truncate">{user.email}</h3>
                </div>
              </Card>
          ))}
        </div>
      )}
      {searchOption === 'categories' && results.length > 0 && (
        <ul className="d-grid gap-1 justify-content-center">
          {(results as ICategory[]).map((category) => (
            <li key={category.id} className="text-center">
              <Link
                className="link-dark fs-1 fst-italic"
                to={'/' + transformStringIntoUrlPath(category.name || '')}
                state={{ id: category?.id }}
              >
                {category.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
      {results.length === 0 && <h1 className="display-1">Following search gave no results ...</h1>}
    </div>
  )
}
export default SearchResults
