import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup'
import AuthService from '../../Services/AuthService'
import iconBack from '../../Assets/icons/arrow-previous-left-icon.svg'
import ModalSuccess from '../../Components/ModalSuccess/ModalSuccess'
import chatImg from '../../Assets/images/Wavy_Tech.svg'
import LandingHeader from '../../Components/WelcomeComponents/LandingHeader/LandingHeader'
import LandingFooter from '../../Components/WelcomeComponents/LandingFooter/LandingFooter'

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please add valid email address')
    .max(255, 'Email is too long')
    .required('Please add email address'),
})

const ForgotPassword: FC = () => {
  const [isModalShow, setIsModalShow] = useState<boolean>(false)
  const navigate = useNavigate()
  return (
    <div className='h-100 d-flex flex-column'>
      <LandingHeader/>

      <div style={{paddingTop:80}} className="flex-grow-1 container">
        {isModalShow && (
          <ModalSuccess isModalShow={isModalShow}>We have sent e-mail with your password reset link</ModalSuccess>
        )}
        <div className="row flex-wrap align-items-center">
          <div className="col-6 d-none d-lg-flex justify-content-center">
            <img src={chatImg} alt="Chat image" width="512" height="512" />
          </div>

          <Formik
            initialValues={{ email: '' }}
            validationSchema={ForgotPasswordSchema}
            onSubmit={async (values, { setFieldError, resetForm }) => {
              // same shape as initial values
              try {
                await AuthService.forgotPassword(values.email)
                setIsModalShow(true)
                setTimeout(() => {
                  setIsModalShow(false)
                }, 2000)
                resetForm()
              } catch (error: any) {
                switch (error.response?.data?.message) {
                  case 'There was an error with sending email':
                    setFieldError('email', 'You have requested password reset recently.')
                    break
                  case 'The user with current email does not exist in our system!':
                    setFieldError('email', "User with this email doesn't exist in our system")
                    break
                  default:
                    setFieldError('email', error.response.data.message)
                }
              }
            }}
          >
            {({ values, errors, touched, handleChange }) => (
              <Form className="col-lg-6">
                <div className="fields d-grid justify-content-center">
                  <h1 className="fs-1 mb-5">Forgot password</h1>
                  <label className="item-label item-row mb-4">
                    <p className="field-title fs-5 mb-3">Email</p>
                    <ErrorMessage name="email" component="div" className="field-error" />
                    <Field
                      value={values.email}
                      onChange={handleChange}
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className={`${errors.email && touched.email ? 'error' : ''} field mb-1`}
                    />
                  </label>

                  <div className="buttons-wrap mb-5">
                    <button onClick={() => navigate('/getaccess')} className="btn-auth">
                      <img className="position-relative" width={20} height={20} src={iconBack} alt="" />
                      <span className="position-absolute start-50 top-50 translate-middle">Back</span>
                    </button>
                    <button type="submit" className="btn-auth">
                      Send
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <LandingFooter/>
    </div>
  )
}

export default ForgotPassword
