import { useLocation } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { ITextCommentAPI } from '../../Interfaces/interfaces'
import './Comments.scss'
import CommentItem from './CommentItem/CommentItem'
import loadMoreIcon from '../../Assets/icons/load_more.svg'
import { Button, Image } from 'react-bootstrap'

const Comments = ({ newCommentsIds, comments }: { newCommentsIds: number[]; comments: ITextCommentAPI[] }) => {
  const location = useLocation()
  const lastHash = useRef('')
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1) // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      document.getElementById(lastHash.current)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
      document.getElementById(lastHash.current)?.classList.add('blinkAnimation')
      lastHash.current = ''
    }
  }, [location])
  return (
    <div className="comment-wrap">
      {comments.map((comment) => (
        <div key={comment.id}>
          <div className={`${newCommentsIds.includes(comment.id) && 'blinkAnimation'} mt-2`} id={comment.id.toString()}>
            <CommentItem comment={comment} />
          </div>
          <div className="comment-wrap">
            {comment.replies && (
              <Replies
                newCommentsIds={newCommentsIds}
                isShowAll={location.state?.id === comment.id}
                replies={comment.replies}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
export default Comments

const Replies = ({
  newCommentsIds,
  replies,
  isShowAll,
}: {
  newCommentsIds: number[]
  replies: ITextCommentAPI[]
  isShowAll?: boolean
}) => {
  const [repliesToShow, setRepliesToShow] = useState<number>(5)

  if (isShowAll) {
    return (
      <>
        {replies.map((reply) => (
          <div
            id={reply.id.toString()}
            key={reply.id}
            className={`${newCommentsIds.includes(reply.id) && 'blinkAnimation'} mt-2 replies replies-toShow`}
          >
            <CommentItem comment={reply} />
          </div>
        ))}
      </>
    )
  }
  return (
    <>
      {replies.map((reply, index) => (
        <div
          id={reply.id.toString()}
          key={reply.id}
          className={`${newCommentsIds.includes(reply.id) && 'blinkAnimation'} mt-2 replies ${repliesToShow > index && 'replies-toShow'}`}
        >
          <CommentItem comment={reply} />
        </div>
      ))}
      {replies.length > repliesToShow && (
        <Button className="loadMore" onClick={() => setRepliesToShow(repliesToShow + 5)}>
          <Image width={16} height={16} src={loadMoreIcon} alt="" />
          Load more
        </Button>
      )}
    </>
  )
}
