import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import AuthService from "../../Services/AuthService";
import chatImg from '../../Assets/images/Wavy_Tech.svg'
import { Col, Container, Row, Stack } from 'react-bootstrap'
import LandingHeader from '../../Components/WelcomeComponents/LandingHeader/LandingHeader'
import LandingFooter from '../../Components/WelcomeComponents/LandingFooter/LandingFooter'

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please add valid email address")
    .max(255, "Email is too long")
    .required("Please add email address"),
  password: Yup.string().min(8, "Password too short").max(50, "Password too long").required("Please add password"),
});

const Login: FC = () => {
  const navigate = useNavigate();

  return (
    <div className='h-100 d-flex flex-column'>
      <LandingHeader/>

      <Container style={{paddingTop:80}} className="flex-grow-1">
        <Row className="align-items-center">
          <Col xs={6} className="d-none d-lg-flex justify-content-center">
            <img src={chatImg} alt="Chat image" width="512" height="512" />
          </Col>

          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={LoginSchema}
            onSubmit={async (values, { setFieldError }) => {
              // same shape as initial values
              try {
                const response = await AuthService.login(values.email, values.password)
                localStorage.setItem('token', response.data.token)
                navigate('/')
              } catch (error) {
                setFieldError('password', 'Invalid Credentials')
              }
            }}
          >
            {({ values, errors, touched, handleChange }) => (
              <Col xs={12} lg={6} className='d-flex justify-content-center'>
              <Form>
                <Stack className="fields justify-content-center">
                  <h1 className="fs-1 mb-5">Login</h1>
                  <label className="item-label item-row mb-4">
                    <p className="field-title fs-5 mb-3">Email</p>
                    <Field
                      value={values.email}
                      onChange={handleChange}
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className={`${errors.email && touched.email ? 'error' : ''} field mb-1`}
                    />
                    <ErrorMessage name="email" component="div" className="field-error" />
                  </label>

                  <label className="item-label item-row mb-5">
                    <p className="field-title fs-5 mb-3">Password</p>
                    <Field
                      value={values.password}
                      onChange={handleChange}
                      type="password"
                      name="password"
                      placeholder="Enter your password"
                      className={`${errors.password && touched.password ? 'error' : ''} field mb-1`}
                    />
                    <ErrorMessage name="password" component="div" className="field-error" />
                  </label>

                  <div className="buttons-wrap mb-5">
                    <button type="submit" className="btn-auth">
                      Sign in
                    </button>
                    {/*<Link className="btn-auth second-btn" to="/registration">*/}
                    {/*  Sign up*/}
                    {/*</Link>*/}
                  </div>

                  <div className="forgot-password-container text-center">
                    <Link className="forgot-password" to="/forgot-password">
                      Forgot password?
                    </Link>
                  </div>
                </Stack>
              </Form>
              </Col>
            )}
          </Formik>
        </Row>
      </Container>

      <LandingFooter/>
    </div>
  )
};

export default Login;
