import './LandingHeader.scss'
import { Link } from 'react-router-dom'
import React from 'react'

import headerLogoWhite from '../../../Assets/icons/logo.svg'

const LandingHeader = ({className}:{className?:string}) => {
  return(
    <>
      <header className={`welcome-header ${className}`}>
        <div className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            <img width={50} src={headerLogoWhite} alt=""/>
            <span className="logo-title">Comment8</span>
          </div>
          <Link to="/getaccess" className="btn-header">
            Login
          </Link>
        </div>
      </header>
    </>
  )
}
export default LandingHeader
