import { getTime } from '../../../utils/utils'
import iconLike from '../../../Assets/icons/Icon-like.svg'
import iconDislike from '../../../Assets/icons/Icon-dislike.svg'
import iconComment from '../../../Assets/icons/Icon-reply.svg'
import React from 'react'
import defaultAvatar from '../../../Assets/icons/account_circle_black.svg'
import './CommentItem.scss'
import { ITextCommentAPI } from '../../../Interfaces/interfaces'
import { followUser } from '../../../Api/api'
import { useAppDispatch } from '../../../redux/store'
import { fetchTextById } from '../../../redux/text.slice'
import { Link, useParams } from 'react-router-dom'
import { useAppSelector } from '../../../redux/store'
import { selectUser } from '../../../redux/user.slice'
import { Button, Card, Col, Row, Image, Stack } from 'react-bootstrap'
import { PUBLIC_PROFILE_PATH, TREAD_PATH } from '../../../utils/const'

const CommentItem = ({ comment }: { comment: ITextCommentAPI }) => {
  const dispatch = useAppDispatch()
  const currentUserID = useAppSelector(selectUser)?.id

  const { id } = useParams()

  const onFollowClick = () => {
    followUser({ user_id: comment.user.id })
      .then(() => {
        dispatch(fetchTextById(+id!))
      })
      .catch((e) => console.log(e))
  }

  function highlightMentions() {
    if (!comment.mentions || !comment.mentions.length) return comment.content
    let currentIndex = 0
    return comment.mentions.reduce((acc, mention) => {
      const index = comment.content.indexOf(`@${mention.username}`, currentIndex)
      if (index === -1) return acc
      const plainText = comment.content.substring(currentIndex, index)
      acc.push(<span key={'plain' + mention.id}>{plainText}</span>)
      const mentionSpan = (
        <Link key={'mention' + mention.id} to={PUBLIC_PROFILE_PATH + '/' + mention.id} className="mention">
          @{mention.username}
        </Link>
      )
      acc.push(mentionSpan)
      currentIndex = index + `@${mention.username}`.length
      return acc
    }, [] as React.ReactElement[])
  }

  return (
    <Card className="comment-item-wrapper rounded-4 p-3">
      <Row>
        <Col md={2} xs={3}>
          <div className={`img-wrapper ${currentUserID == comment.user.id && 'img-wrapper--current-user'}`}>
            <Image
              roundedCircle
              width={90}
              height={90}
              src={comment.user?.avatar || defaultAvatar}
              className="user-favicon object-fit-cover"
              alt=""
            />
            {currentUserID !== comment.user.id && (
              <Button variant="dark" onClick={onFollowClick} className="middle rounded-4">
                {comment.user?.isFollowing ? 'Unfollow' : 'Follow'}
              </Button>
            )}
          </div>
        </Col>
        <Col md={7} xs={9}>
          <Card.Body className="text-break">
            <Stack direction="horizontal" gap={2} className="flex-wrap">
              <Card.Title
                as={Link}
                style={{ textDecoration: 'none' }}
                to={PUBLIC_PROFILE_PATH + '/' + comment.user?.id}
                className="card-title fs-4"
              >
                {comment.user?.username || comment.user?.name}
              </Card.Title>
              <span className="mb-2">{getTime(comment.date)}</span>
            </Stack>

            <Card.Text>
              {/*{comment.content}*/}
              {highlightMentions()}
            </Card.Text>
          </Card.Body>
        </Col>
        <Col md={3} xs={12} className="d-flex align-items-center justify-content-center gap-4 my-2">
          <Stack direction="horizontal" gap={1}>
            <Card.Link>
              <img className="mb-1 card-icon" src={iconLike} width="24" height="24" alt="" />
            </Card.Link>
            {comment.likesCount}
          </Stack>
          <Stack direction="horizontal" gap={1}>
            <Card.Link>
              <img className="mt-1 card-icon" src={iconDislike} width="24" height="24" alt="" />
            </Card.Link>
            {comment.dislikesCount}
          </Stack>
          <Stack direction="horizontal" gap={1}>
            <Card.Link as={Link} to={TREAD_PATH + '/' + comment.id}>
              <img className="card-icon" src={iconComment} width="24" height="24" alt="" />
            </Card.Link>
            {comment.repliesCount || 0}
          </Stack>
        </Col>
      </Row>
    </Card>
  )
}

export default CommentItem
