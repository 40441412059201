import "./PrivacyPolicy.scss";

const PrivacyPolicy = () => {
  return (
    <div className="policy-wrapper">
        <h1>Comment8 Privacy Policy</h1>
        <h3>Section 1: Introduction</h3>
        <span className='strong'>Welcome to Comment8</span> <br />
        <p>
          Thank you for choosing Comment8. This Privacy Policy applies to all users of our browser plugin and outlines
          our practices regarding the collection, use, and handling of your personal data.{" "}
        </p>
        <span className='strong'>Overview of Our Service</span> <br />
        <p>
          Comment8 is a browser plugin designed to enhance your browsing experience by enabling you to leave comments on
          any webpage. This unique feature allows for a more interactive and engaging internet experience, where your
          thoughts and insights can be shared directly on the sites you visit.
        </p>
        <p>
          When you use Comment8, your comments, along with certain related data, are stored on our servers. This Privacy
          Policy explains what information we collect, how we use it, and the steps we take to protect your privacy.
        </p>
        <span className='strong'>Commitment to Privacy</span> <br />
        <p>
          Our dedication to your privacy is unwavering. We aim to handle your personal information with utmost
          transparency and responsibility. This Privacy Policy is crafted to assist you in understanding our privacy
          practices, including the nature of the information we collect, the reasons for its collection, and how we
          utilize it, in addition to clarifying your rights as a user.
        </p>
        <p>
          Please read this policy attentively to comprehend our perspectives and methods concerning your personal data
          and the manner in which we will treat it. By engaging with Comment8, you consent to the collection and use of
          information in accordance with this policy.
        </p>
        <h3>Section 2: Data Collection </h3>
        <span className='strong'>Types of Data Collected</span> <br />
        <p>
          In order to provide and improve Comment8's services, we collect various types of data from our users. This
          includes:
        </p>
        <p>
          User-Generated Content: This encompasses all comments made by users on various websites through the Comment8
          plugin. It also includes any metadata associated with these comments, such as the time and date of posting and
          the URL of the webpage on which the comment was made.
        </p>
        <p>
          Account Information: If users create accounts, we collect information such as usernames, email addresses, and
          any other details provided during the registration process.
        </p>
        <p>
          Interaction Data: This refers to data related to how users interact with the Comment8 plugin, including usage
          statistics, user preferences, and responses to surveys or feedback requests.
        </p>
        <p>
          Technical Data: We collect technical information such as IP addresses, browser types, browser language, and
          other information related to the user's interaction with the Comment8 plugin.
        </p>
        <p>
          Cookies and Tracking Technologies: Comment8 uses cookies and similar tracking technologies to track user
          activity on our plugin and hold certain information. This helps in enhancing user experience and service
          efficiency.
        </p>
        <span className='strong'>Method of Data Collection</span> <br />
        <p>Data is collected in several ways:</p>
        <p>
          Directly from Users: When users interact with Comment8, such as by posting comments, creating an account, or
          adjusting their user preferences.
        </p>
        <p>
          Automatically through the Plugin: This includes collecting technical data and usage information as users
          navigate through websites with the Comment8 plugin active.
        </p>
        <p>
          Through Cookies and Tracking Technologies: These are used to gather data about how users interact with our
          plugin, helping us to tailor and improve our services.
        </p>
        User Consent and Control <br />
        <p>
          We ensure that user consent is obtained wherever necessary, particularly in cases involving sensitive data.
        </p>
        <span className='strong'>Data Minimization</span> <br />
        <p>
          We adhere to the principle of data minimization, ensuring that only the data necessary for providing and
          improving our services is collected. Our goal is to respect user privacy while delivering an optimal
          experience with Comment8.
        </p>
        <h3>Section 3: Use of Data </h3>
        <span className='strong'>Purpose of Data Usage</span> <br />
        <p>The data collected by Comment8 is used for several key purposes:</p>
        <p>
          <span className='strong'>Service Provision and Enhancement:</span> To deliver and maintain the functionality of the Comment8
          plugin, including managing user accounts, enabling comment posting, and ensuring the overall smooth operation
          of the service. We also use the collected data to improve and personalize the user experience, and to develop
          new features based on user interactions and feedback.
        </p>
        <p>
          <span className='strong'>User Support and Communication:</span> To communicate with users regarding service updates, support
          issues, or policy changes. This includes responding to user inquiries and providing assistance with the use of
          Comment8.
        </p>
        <p>
          <span className='strong'>Security and Fraud Prevention:</span> To enhance the security of our services, detect and prevent
          fraudulent activities, and ensure compliance with our terms of service and legal obligations.
        </p>
        <p>
          <span className='strong'>Data Analysis and Research:</span> To conduct analysis and research for understanding user behavior
          and preferences, which helps in making informed decisions to improve the Comment8 plugin.
        </p>
        <p>
          <span className='strong'>Advertising and Marketing:</span> We use customer data to tailor advertising and marketing efforts.
          This includes using user data to display personalized advertisements, both within and outside the Comment8
          plugin, and to measure the effectiveness of these advertising campaigns. Our goal is to present ads that are
          relevant and interesting to our users.
        </p>
        <p>
          <span className='strong'>Consent for Advertising Purposes</span> <br />
          We obtain explicit consent from our users for the use of their data in advertising. Users have the option to
          opt-out of personalized advertising at any time through their account settings.
        </p>
        <p>
          <span className='strong'>Data Sharing for Advertising</span> <br />
          In cases where we share user data with third-party advertisers or marketing agencies, we ensure that these
          parties adhere to strict data protection and privacy standards. We disclose only non-identifiable data unless
          explicit consent for sharing identifiable data is obtained from the user.
        </p>
        <h3>Section 4: Data Security </h3>
        <p>
          <span className='strong'>Commitment to Protecting User Data</span> <br />
          At Comment8, we recognize the importance of securing your personal information and are committed to protecting
          it from unauthorized access, alteration, disclosure, or destruction. We employ a variety of administrative,
          technical, and physical security measures to safeguard your data.
        </p>
        <p>
          <span className='strong'>Data Encryption and Secure Transmission</span> <br />
          To protect the integrity and privacy of the data transmitted through the Comment8 plugin, we use
          industry-standard encryption protocols. This ensures that all data, including comments and personal
          information, is securely transmitted and stored.
        </p>
        <p>
          <span className='strong'>Secure Data Storage</span> <br />
          Our servers, where user data is stored, are secured with state-of-the-art security measures. We regularly
          update and test our security technology to ensure the protection of your data against any unauthorized access
          or potential security breaches.
        </p>
        <p>
          <span className='strong'>Access Control and Authentication</span> <br />
          We implement strict access control measures to ensure that only authorized personnel have access to user data.
          Access to sensitive data is restricted and closely monitored. Employees are trained in data protection and are
          bound by confidentiality agreements.
        </p>
        <p>
          <span className='strong'>Regular Security Audits</span> <br />
          Regular internal security audits are conducted to assess the effectiveness of our data protection measures.
          These audits help us identify potential vulnerabilities and implement corrective actions to strengthen our
          security posture.
        </p>
        <p>
          <span className='strong'>Response to Security Breaches</span> <br />
          In the unlikely event of a data breach, we have in place a comprehensive incident response plan. Affected
          users will be informed promptly, and all necessary steps will be taken to mitigate the breach and prevent
          future occurrences.
        </p>
        <p>
          <span className='strong'>User Participation in Data Security</span> <br />
          We encourage users to participate in protecting their data. Users are advised to use strong, unique passwords
          for their accounts and to be cautious of phishing and other types of online scams.
        </p>
        <p>
          <span className='strong'>Continuous Improvement</span> <br />
          Our commitment to data security is ongoing. We continuously monitor security trends and update our practices
          to address new challenges and technological advancements.
        </p>
        <h3>Section 5: Cookies and Tracking Technologies </h3>
        <p>
          <span className='strong'>Use of Cookies and Other Tracking Technologies</span> <br />
          Comment8 may utilize cookies and similar tracking technologies to enhance user experience, understand user
          behavior, and improve our service. This section explains how and why we may decide to use these technologies.
        </p>
        <p>
          What are Cookies?: Cookies are small data files stored on your device that help websites remember your
          preferences and collect information about your usage patterns. They play a crucial role in making your
          interaction with Comment8 more efficient and personalized.
        </p>
        <p>Types of Cookies Used: We use various types of cookies, including:</p>
        <p>
          Session Cookies: Temporary cookies that remain in the cookie file of your browser until you leave the site.{" "}
          <br />
          Persistent Cookies: Stored on your device for a longer period and used to remember your preferences for future
          visits. <br />
          Third-Party Cookies: Placed by service providers we partner with to help us understand how our plugin is used
          and to deliver targeted advertising. <br />
          Purpose of Cookies: Cookies and tracking technologies are used for several purposes, such as:
        </p>
        <p>
          Essential Functions: To enable basic functions like page navigation and access to secure areas of the plugin.{" "}
          <br />
          Performance and Analytics: To gather information on how users interact with Comment8, helping us to identify
          areas for improvement.
        </p>
        <p>
          Personalization: To remember your preferences and settings, making your interaction with Comment8 more
          personalized.
        </p>
        <p>
          Advertising: To show relevant advertisements on and off the Comment8 service and measure the effectiveness of
          these ads.
        </p>
        <p>
          User Control Over Cookies and Tracking Technologies <br />
          You have control over the use of cookies and tracking technologies:
        </p>
        <p>
          Browser Settings: Most web browsers are set to accept cookies by default, but you can change your browser
          settings to refuse cookies or to alert you when cookies are being sent.
        </p>
        <p>
          Implications of Disabling Cookies: While you can browse without cookies, please note that disabling cookies
          may affect the functionality and features of the Comment8 plugin, potentially limiting your user experience.
        </p>
        <h3>Section 6: Data Retention and Access </h3>
        Data Retention Policy <br />
        <p>
          At Comment8, we are committed to only retaining user data for as long as necessary to fulfill the purposes for
          which it was collected. This section outlines our data retention practices:
        </p>
        <p>
          Duration of Data Retention: User data is stored for a period that is necessary to provide our services, comply
          with legal obligations, resolve disputes, and enforce our agreements. The specific retention period depends on
          the nature of the data and the purposes for which it is processed.
        </p>
        <p>
          Criteria for Determining Retention Periods: We consider various factors when determining data retention
          periods, including:
        </p>
        <p>
          Legal Requirements: Compliance with laws and regulations that require data to be kept for a certain period.
        </p>
        <p>
          User Account Status: Data associated with active user accounts is retained as long as the account remains
          active. For inactive accounts, data may be retained for a specific period before being deleted or anonymized.
        </p>
        <p>
          Nature of Data: Different types of data may have different retention needs based on their use and sensitivity.
        </p>
        <p>
          Access Requests: Users can request access to their personal data to review, verify its accuracy, and check for
          lawful processing. Such requests can be made by contacting our support team.
        </p>
        <p>
          Request for Data Deletion: If a user wishes to discontinue the use of Comment8 and have their data deleted,
          they can make a request through their account settings or by contacting our support team.
        </p>
        <p>
          Criteria for Data Deletion: Requests for data deletion will be honored in accordance with legal obligations,
          with respect to data retention for regulatory, tax, insurance, and other legal purposes.
        </p>
        <p>
          Handling of Data Requests <br />
          All user requests related to data access, portability, correction, or deletion will be handled in a timely and
          efficient manner. We aim to respond to user requests within a reasonable timeframe, in accordance with
          applicable data protection laws.
        </p>
        <h3>Section 7: User Rights </h3>
        <p>
          Understanding Your Rights as a User of Comment8 <br />
          Comment8 is committed to ensuring that you fully understand and are able to exercise your rights regarding
          your personal data. This section outlines these rights and how you can exercise them.
        </p>
        <p>
          Right to Access: You have the right to access the personal data we hold about you. This includes knowing the
          purposes of processing, the categories of personal data concerned, and the recipients or categories of
          recipients to whom the personal data have been or will be disclosed.
        </p>
        <p>
          Right to Rectification: If your personal data is inaccurate or incomplete, you have the right to request that
          we correct or complete it.
        </p>
        <p>
          Right to Erasure (‘Right to be Forgotten’): You may request the deletion of your personal data, especially if
          the data is no longer necessary for the purposes for which it was collected, or if you withdraw consent on
          which the processing is based.
        </p>
        <p>
          Right to Restriction of Processing: Under certain conditions, you have the right to restrict the processing of
          your personal data, meaning that we may store your data but not use it further. This right applies in specific
          circumstances, such as when you contest the accuracy of your data or have objected to our use of it.
        </p>
        <p>
          Right to Data Portability: You have the right to receive your personal data in a structured, commonly used,
          and machine-readable format, and you have the right to transmit that data to another controller without
          hindrance from us.
        </p>
        <p>
          Right to Object: You have the right to object to the processing of your personal data, particularly where we
          are processing it for direct marketing purposes or where the processing is based on our legitimate interests.
        </p>
        <p>
          Rights in Relation to Automated Decision Making and Profiling: You have the right not to be subject to a
          decision based solely on automated processing, including profiling, which produces legal effects concerning
          you or similarly significantly affects you.
        </p>
        <p>
          Exercising Your Rights <br />
          To exercise any of these rights, please contact us through the provided contact channels in our plugin or
          website. We will respond to your request in accordance with applicable data protection laws, and may require
          proof of identity to process certain requests.
        </p>
        <p>
          Responses to Requests <br />
          We are committed to responding to all legitimate requests within one month. Occasionally, it may take us
          longer if your request is particularly complex or you have made several requests. In this case, we will notify
          you and keep you updated.
        </p>
        <p>
          Complaints <br />
          If you have any concerns or complaints about how we handle your personal data, we encourage you to contact us
          first. You also have the right to lodge a complaint with a data protection authority in the jurisdiction where
          you live, work, or where you believe a breach may have occurred.
        </p>
        <h3>Section 8: Policy Updates </h3>
        <p>
          Commitment to Regular Policy Review and Updates <br />
          At Comment8, we are committed to continually enhancing our privacy practices and ensuring our Privacy Policy
          remains up-to-date with current regulations, technological advancements, and best practices. This section
          details how we manage updates to this policy.
        </p>
        <p>
          Periodic Review and Update of Policy: We regularly review and update this Privacy Policy to reflect changes in
          our data practices, new legal requirements, and feedback from our users. This is part of our commitment to
          transparency and compliance with data protection laws.
        </p>
        <p>
          Notification of Changes: When we make changes to this policy, we will notify users through our plugin,
          website, or via email. Significant changes will be clearly communicated to ensure our users are aware of what
          these changes mean for them.
        </p>
        <p>
          Effective Date of Updated Policy: The date of the last update to our Privacy Policy will be clearly indicated
          at the top of the policy document. Changes to the policy will become effective as of this date.
        </p>
        <p>
          Access to Previous Versions: We will archive previous versions of our Privacy Policy. Users can request access
          to these versions for review.
        </p>
        <p>
          User Responsibility to Stay Informed: We encourage all our users to regularly review this Privacy Policy.
          Continued use of Comment8 after any changes to the Privacy Policy constitutes acceptance of those changes.
        </p>
        <p>
          User Feedback and Suggestions <br />
          We value user feedback regarding our Privacy Policy. Users are encouraged to send their suggestions or
          concerns, which we consider when making updates to the policy. This collaborative approach helps us ensure
          that our policy remains user-centric and effective in protecting user privacy.
        </p>
        <h3>Section 9: Contact Information </h3>
        <p>
          Communication and Support <br />
          At Comment8, we prioritize open communication with our users, especially regarding their privacy and data
          protection concerns. This section provides detailed information on how you can get in touch with us for any
          inquiries, support needs, or feedback.
        </p>
        <p>
          Feedback and Suggestions: We welcome and value user feedback on our services and privacy practices. Feedback
          can be provided through our website, plugin, or directly via email.
        </p>
        <p>
          Language and Communication Accessibility <br />
          We strive to make our communication as accessible as possible. If you require assistance in another language
          or have specific communication needs, please let us know, and we will do our best to accommodate you.
        </p>
        <p>
          User Rights and Data Requests <br />
          For requests regarding your data rights, such as access, correction, deletion, or data portability, please use
          the specific contact details provided in Section 7 of this policy.
        </p>
        <h3>Section 10: Governing Law and Jurisdiction </h3>
        <p>
          Legal Framework and Compliance <br />
          The operation of Comment8 and the implementation of this Privacy Policy are governed by the laws of the
          jurisdiction in which our company is established. This section outlines the legal framework and jurisdiction
          applicable to our privacy practices.
        </p>
        <p>
          <span className='strong'>Applicable Laws:</span> Comment8 adheres to the data protection and privacy laws of [Specify
          Jurisdiction/Country]. These laws provide the basis for our data processing activities and our commitment to
          protecting user privacy.
        </p>
        <p>
          <span className='strong'>Jurisdiction:</span> Any disputes or legal issues arising in connection with this Privacy Policy are
          subject to the jurisdiction of the courts of [Specify Jurisdiction/Country]. We commit to resolving any such
          issues in accordance with the laws of this jurisdiction.
        </p>
        <p>
          <span className='strong'>International Data Transfers:</span> If you are accessing Comment8 from outside [Specify
          Jurisdiction/Country], please be aware that your data may be transferred to, stored, and processed in our
          servers located in [Specify Jurisdiction/Country]. By using Comment8, you agree to this transfer, storage, and
          processing.
        </p>
        <p>
          <span className='strong'>Compliance with International Standards:</span> While Comment8 is governed primarily by the laws of
          the State of Illinois, we strive to adhere to internationally recognized standards and best practices in data
          protection and privacy.
        </p>
        <p>
          <span className='strong'>User Rights Across Jurisdictions</span> <br />
          We recognize that users may come from various jurisdictions with different data protection laws. Comment8
          endeavors to respect the data protection rights afforded to users under their local laws to the extent
          possible.
        </p>
        <h3>Section 11: Effective Date </h3>
        <p>
          Implementation of the Privacy Policy <br />
          This Privacy Policy comes into effect from 12/5/2023, which is the date of its most recent revision. This
          section provides information on the implementation and acknowledgement of the policy.
        </p>
        <p>
          Effective Date: The Privacy Policy is effective as of 12/5/2023 and will remain in effect except with respect
          to any changes in its provisions in the future, which will be in effect immediately after being posted on this
          page or notified to users.
        </p>
        <p>
          Acknowledgement of Policy: By using Comment8, you acknowledge that you have read and understood this Privacy
          Policy and agree to be bound by its terms.
        </p>
        <p>
          Continued Use as Acceptance: Continued use of Comment8 following any changes to this Privacy Policy will
          constitute your acceptance of those changes. We encourage users to frequently check this page for any updates
          to stay informed about how we are protecting the personal information we collect.
        </p>
      </div>
  );
};
export default PrivacyPolicy;
