import React, { useCallback, useEffect, useRef, useState } from "react";
import { getUserComments } from "../../Api/api";
import { IPaginationOld, ITextCommentAPI } from "../../Interfaces/interfaces";
import { Link } from "react-router-dom";
import defaultAvatar from "../../Assets/icons/account_circle_black.svg";
import { getTime } from "../../utils/utils";
import iconLike from "../../Assets/icons/Icon-like.svg";
import iconDislike from "../../Assets/icons/Icon-dislike.svg";
import iconComment from "../../Assets/icons/Icon-reply.svg";
import Loader from '../../Components/Loader/Loader'
import { SOURCE_PAGE_PATH } from '../../utils/const'

const MyCommentsAndActivity = () => {
  const [hasMore, setHasMore] = useState<boolean>(true);
  //set the all text that we have, by default, if we don't have any category chosen, it will stay the same
  const [comments, setComments] = useState<ITextCommentAPI[]>([]);
  //and the same with pagination
  const [pagination, setPagination] = useState<IPaginationOld | null>(null);

  const [isFetching, setIsFetching] = useState(true);

  const loadMore = useCallback(async () => {
    if (!pagination) return;
    getUserComments(pagination.current_page + 1).then((res) => {
      if (res.data.comments.data.length === 0) setHasMore(false);
      setComments([...comments, ...res.data.comments.data]);
      setPagination(res.data.comments.pagination);
    });
  }, [pagination]);

  useEffect(() => {
    setIsFetching(true)
    getUserComments().then((res) => {
      setComments(res.data.comments.data);
      setPagination(res.data.comments.pagination);
    });
    setIsFetching(false)
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
      if (scrollHeight - scrollTop - clientHeight < 200 && hasMore) {
        loadMore();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loadMore]);
  if(isFetching || !comments)return <Loader/>
  return (
    <div>
      <div className="row d-flex flex-wrap justify-content-center">
        {comments.map((comment) => (
          <div key={comment.id} className="my-1">
            <Link
              style={{ textDecoration: "none" }}
              to={`${SOURCE_PAGE_PATH}/${comment.text.id}#${comment.id}`}
              state={{ id: comment.parent?.id }}
            >
              <div className="comment-item-wrapper">
                <div className="card rounded-4 p-3">
                  <div className="row">
                    <div className="col-lg-2 col-xs-6">
                      <img
                        width={100}
                        height={100}
                        src={comment.text.favicon || defaultAvatar}
                        className="object-fit-cover  rounded-circle"
                        alt=""
                      />
                    </div>
                    <div className="col-lg-7 col-xs-6">
                      <div className="card-body">
                        <div className="d-flex align-items-center gap-2">
                          <h2 className="card-title fs-4">{comment.text.title}</h2>
                          <span className="text-nowrap mb-2">{getTime(comment.date)}</span>
                        </div>

                        <p className="card-text">{comment.content}</p>
                      </div>
                    </div>
                    <div className="col-lg-3 col-xs-12 d-flex align-items-center justify-content-center gap-4  my-2">
                      <span className="d-flex align-items-center gap-1">
                        <span className="card-link nav-link">
                          <img className="mb-1 card-icon" src={iconLike} width="24" height="24" alt="" />
                        </span>
                        {comment.likesCount}
                      </span>
                      <span className="d-flex align-items-center gap-1">
                        <span className="card-link nav-link">
                          <img className="mt-1 card-icon" src={iconDislike} width="24" height="24" alt="" />
                        </span>
                        {comment.dislikesCount}
                      </span>
                      <span className="d-flex align-items-center gap-1">
                        <span className="card-link nav-link">
                          <img className="card-icon" src={iconComment} width="24" height="24" alt="" />
                        </span>
                        {comment.repliesCount}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};
export default MyCommentsAndActivity;
