import React, { useEffect, useState } from 'react'
import { followUser, getUserProfile } from '../../Api/api'
import { Link, useParams } from 'react-router-dom'
import { IUser } from '../../Interfaces/interfaces'
import defaultAvatar from '../../Assets/icons/account_circle_black.svg'
import Loader from '../../Components/Loader/Loader'
import { useAppSelector } from '../../redux/store'
import { selectUser } from '../../redux/user.slice'
import './PublicProfile.scss'

const PublicProfile = () => {
  const { id } = useParams()
  const [userProfile, setUserProfile] = useState<IUser | null>(null)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const currentUserID = useAppSelector(selectUser)?.id

  useEffect(() => {
    if (id)
      getUserProfile(+id).then((res) => {
        setUserProfile(res.data.user)
        setIsFetching(false)
      })
  }, [id])
  if (isFetching || !userProfile) return <Loader />
  return (
    <>
      <div className="public-profile-wrapper row align-items-start">
        {/*Avatar*/}
        <div className="col-12 mb-3 userInfo">
          <div className="card rounded-4">
            <div className="card-body">
              <div className="d-flex flex-column align-items-center text-center">
                {/**/}
                <div className={`img-wrapper ${currentUserID == userProfile.id && 'img-wrapper--current-user'}`}>
                  <img
                    width={250}
                    height={250}
                    src={userProfile.avatar || defaultAvatar}
                    className="user-favicon object-fit-cover  rounded-circle"
                    alt=""
                  />
                  {currentUserID !== userProfile.id && (
                    <button
                      onClick={() => {
                        followUser({ user_id: userProfile.id }).then(() => {
                          setUserProfile({ ...userProfile, isFollowing: !userProfile?.isFollowing })
                        })
                      }}
                      className="middle btn btn-dark rounded-4"
                    >
                      {userProfile.isFollowing ? 'Unfollow' : 'Follow'}
                    </button>
                  )}
                </div>
                <div className="mt-3">
                  <div>{userProfile.name}</div>
                  <div className="text-muted">{userProfile.username}</div>
                  <div>
                    <Link to={`mailto:${userProfile.email}`}>{userProfile.email}</Link>
                  </div>
                  <div>
                    <span>Followers:{userProfile.followers_count}</span>
                    <span> Followings:{userProfile.followings_count}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/**/}

        <div className="col-12  biography">
          <h1 className="mb-2">Biography</h1>
          <div className="card card-body rounded-4">
            <p>{userProfile.biography || 'No biography'}</p>
          </div>
        </div>
      </div>
    </>
  )
}
export default PublicProfile
