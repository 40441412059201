import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import './SearchBar.scss'
import searchIcon from '../../Assets/icons/Icon-search.svg'
import { useDebouncedCallback } from 'use-debounce'
import { getSearchResults } from '../../Api/api'
import { ICategory, ITextCommentAPI, ITextResponseAPI, IUser } from '../../Interfaces/interfaces'
import { Image, InputGroup, Form } from 'react-bootstrap'
import SearchResults from './SearchResults/SearchResults'
import { SEARCH_RESULTS_PATH } from '../../utils/const'

export type SearchOptions = 'users' | 'texts' | 'categories' | 'comments'
export type SearchResult = ITextResponseAPI | IUser | ITextCommentAPI | ICategory

const SearchBar = ({ onSearch }: { onSearch: () => void }) => {
  const searchInputRef = useRef<HTMLInputElement>(null)
  const [isSearchFocus, setIsSearchFocus] = useState<boolean>(false)
  const navigate = useNavigate()

  function handleBlur(e: MouseEvent) {
    const targetElement = e.target as HTMLElement
    if (
      targetElement.id === 'searchMask' ||
      targetElement.id === 'crx-root' ||
      targetElement.tagName === 'MAIN' ||
      targetElement.tagName === 'A' ||
      (targetElement.tagName === 'IMG' && targetElement.className !== 'input-group-text')
    ) {
      setIsSearchFocus(false)
      setInputError(null)
      searchInputRef.current && searchInputRef.current.blur()
    }
  }

  useEffect(() => {
    setSearchPhrase('')
    document.addEventListener('click', handleBlur)
    return () => document.removeEventListener('click', handleBlur)
  }, [navigate])

  const [searchPhrase, setSearchPhrase] = useState<string>('')
  const [inputError, setInputError] = useState<string | null>()
  const [activeTab, setActiveTab] = useState<SearchOptions>('comments')

  const [results, setResults] = useState<SearchResult[]>([])

  const debouncedSearch = useDebouncedCallback((searchPhrase) => {
    getSearchResults({ searchPhrase, option: activeTab }).then((res) => {
      setResults(res.data.search)
    })
  }, 300)

  const handleTabChange = (option: SearchOptions) => {
    setResults([])
    if (searchPhrase.length >= 3) {
      getSearchResults({ searchPhrase: searchPhrase, option }).then((res) => {
        setResults(res.data.search)
      })
    }
  }

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setResults([])
    setSearchPhrase(e.target.value)
    if (e.target.value.length >= 3) {
      setInputError(null)
      debouncedSearch(e.target.value)
    }
  }
  const handleSearchSubmit = () => {
    if (searchPhrase.length >= 3) {
      onSearch()
      navigate(`${SEARCH_RESULTS_PATH}?search=${searchPhrase}&option=${activeTab}`)
      setIsSearchFocus(false)
      searchInputRef.current && searchInputRef.current.blur()
    } else setInputError('At least 3 characters')
  }
  return (
    <>
      <div className="search-bar-wrapper">
        {/*Search input*/}
        <div className="justify-content-center d-flex mx-0 mx-xl-3">
          <InputGroup className={`${inputError && 'error'} search-bar`}>
            <Form.Control
              id="search"
              type="search"
              placeholder="Search"
              autoComplete="off"
              value={searchPhrase}
              ref={searchInputRef}
              className="form-control"
              onClick={() => setIsSearchFocus(true)}
              onChange={handleSearchChange}
              onKeyDown={(e) => {
                e.key === 'Enter' && handleSearchSubmit()
              }}
            />
            <InputGroup.Text onClick={handleSearchSubmit}>
              <Image width={20} height={20} src={searchIcon} alt="search icon" />
            </InputGroup.Text>
          </InputGroup>
          {inputError && <span className="error-message d-xl-none">{inputError}</span>}
        </div>

        {/*Search options*/}
        <div className={`search-options ${!isSearchFocus && 'hidden'} `}>
          <div
            onClick={() => {
              handleTabChange('comments')
              setActiveTab('comments')
            }}
            className={`${activeTab === 'comments' && 'active'} search-tab`}
          >
            Comment
          </div>
          <div
            onClick={() => {
              handleTabChange('texts')
              setActiveTab('texts')
            }}
            className={`${activeTab === 'texts' && 'active'} search-tab`}
          >
            Source
          </div>
          <div
            onClick={() => {
              handleTabChange('users')
              setActiveTab('users')
            }}
            className={`${activeTab === 'users' && 'active'} search-tab`}
          >
            User
          </div>
          <div
            onClick={() => {
              handleTabChange('categories')
              setActiveTab('categories')
            }}
            className={`${activeTab === 'categories' && 'active'} search-tab`}
          >
            Category
          </div>
        </div>

        {/*Search results*/}
        <SearchResults
          keepFocus={() => setIsSearchFocus(false)}
          hidden={searchPhrase.length < 3 || !isSearchFocus}
          results={results}
          activeTab={activeTab}
          showAll={() => navigate(`${SEARCH_RESULTS_PATH}?search=${searchPhrase}&option=${activeTab}`)}
        />
      </div>
      {/*Mask*/}
      <div id="searchMask" className={`${!isSearchFocus && 'mask-hidden'} mask`}></div>
    </>
  )
}
export default SearchBar
